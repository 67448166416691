import { useEffect, useState, useMemo, useCallback, useRef } from "react";
import React from "react";

import { CloseIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  ButtonGroup,
  Center,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  IconButton,
  Spinner,
  Text,
  useToast,
  VStack,
  Stack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Divider,
  Alert,
  AlertIcon,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { FaDiamond } from "react-icons/fa6";
import { IoWarningOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

import { AthleteContentSection } from "../../../../../../shared/athleteModels";
import { RoutePath } from "../../../../../../shared/models";
import { emptySectionStarter } from "../../../../../../shared/resumeContentConfig";
import { useAppStore } from "../../../app-store";
import { AthleteProfileContentCard } from "../../../components/cards/AthleteProfileContentCard";
import { GenericFileUpload } from "../../../components/files/GenericFileUpload";
import { Page } from "../../../components/Page";
import ProfileCompletionTile from "../../../components/tiles/ProfileCompletionTile";
import VideoTile from "../../../components/tiles/VideoTile";
import {
  useAthleteContentMutation,
  useAthleteProfile,
  useAthleteProfileMutation,
  useDeleteAthleteContentMutation,
} from "../../../hooks/useAthleteProfile";
import { useProfileSections } from "../../../hooks/useProfileSections";
import { useResumeUpload } from "../../../hooks/useResumeUpload";
import { getProfileImage } from "../../../services/file-api";
import { BuildAthleteInfo } from "../../onboarding/athlete-onboarding/BuildAthleteInfo";
import AthleteProfileCard from "../AthleteProfileCard";

import MessageTile from "~/components/tiles/MessageTile";
import { listenToUserConversations } from "~/services/firebase-service";
import { MessagesApi } from "~/services/messages-api";

export interface DashboardProfileSection extends AthleteContentSection {
  isNew?: boolean;
  isEmpty?: boolean;
  isUpdating?: boolean;
  isComplete?: boolean;
}

const ToastSaveAllComponent = (
  handleSaveAll: () => void,
  handleDiscardAll: () => void
) => {
  return (
    <Box
      bg="#EDB02B"
      backdropFilter="blur(10px)"
      border="2px solid white"
      opacity={0.9}
      p={4}
      py={2}
      mt={[8, 0]}
      borderRadius="lg"
      maxW="600px"
      w="full"
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      gap={2}
    >
      <Heading size={["sm", "md"]}>AI Built Resume Profile Updates</Heading>
      <HStack>
        <Button variant="outline" colorScheme="black" size={["sm", "md"]} border="2px solid black" onClick={handleSaveAll}>
          Save All
        </Button>
        <Popover>
          <PopoverTrigger>
            <IconButton aria-label="Discard changes" icon={<CloseIcon />} variant="ghost" colorScheme="blackAlpha" />
          </PopoverTrigger>
          <PopoverContent>
            <PopoverHeader fontWeight="semibold">Discard Unsaved Changes?</PopoverHeader>
            <PopoverBody>All unsaved changes will be lost.</PopoverBody>
            <PopoverFooter display="flex" justifyContent="flex-end">
              <ButtonGroup size="sm">
                <Button variant="ghost" colorScheme="red" onClick={handleDiscardAll}>
                  Discard Unsaved
                </Button>
              </ButtonGroup>
            </PopoverFooter>
          </PopoverContent>
        </Popover>
      </HStack>
    </Box>
  );
};

//FIXME: ** LOOK AT ME **
//FIXME: In general things are functioning, but I need to handle the nuances of someone onboarding and handling all the different permutations of their actions.
//FIXME: ** LOOK AT ME **

export function AthleteDashboardPage() {
  const navigate = useNavigate();
  const [userInfo] = useAppStore((state) => [state.userInfo]);
  const [isOnboarding, setIsOnboarding] = useAppStore((state) => [state.isOnboarding, state.setIsOnboarding]);
  const [suppressPageLoading, setSuppressPageLoading] = useState(false);
  const [showAthleteDetailsForm, setShowAthleteDetailsForm] = useState(false);
  const [showResumeUploadModal, setShowResumeUploadModal] = useState(false);
  const [isUploadingResume, setIsUploadingResume] = useState(false);
  const [sections, setSections] = useState<DashboardProfileSection[]>([]);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean | null>(null);
  const [athleteResumeData, setAthleteResumeData] = useAppStore((state) => [
    state.athleteResumeData,
    state.setAthleteResumeData,
  ]);
  const {
    data: athleteProfile,
    isSuccess: isSuccessAthleteProfile,
    isFetching: isFetchingAthleteProfile,
  } = useAthleteProfile();
  const toast = useToast();

  // Add query for LinkedIn import request data
  const { data: linkedInImportData } = useQuery({
    queryKey: ["linkedInImportRequest"],
    queryFn: () => athleteProfile?.hasLinkedInImport ? MessagesApi.getLinkedInImportRequest() : null,
    enabled: !!athleteProfile?.hasLinkedInImport,
  });

  // Log LinkedIn import data when available and update viewedByUser if needed
  useEffect(() => {
    if (linkedInImportData) {
      // If there's a completion date but no viewedByUser date, update it
      if (linkedInImportData.completionDate && !linkedInImportData.viewedByUser) {
        MessagesApi.updateLinkedInImportViewed()
      }
    }
  }, [linkedInImportData]);

  const {
    data: profileImage,
    isLoading: isLoadingProfileImage,
    isFetching: isFetchingProfileImage,
  } = useQuery({
    queryKey: ["profileImage"],
    queryFn: getProfileImage,
    refetchOnMount: false,
  });

  const { determineColSpan, mergeSections, groupFlexSectionsIntoRows } = useProfileSections();
  const { mutate: updateAthleteContent, isPending: isUpdatingContentSection } = useAthleteContentMutation();
  const { mutate: updateAthleteProfile, isPending: isUpdatingProfile } = useAthleteProfileMutation();
  const { saveResume, uploadFullResume, returnedResumeData: reuploadedResumeData } = useResumeUpload();
  const deleteAthleteContentMutation = useDeleteAthleteContentMutation();

  const [resumeToUpload, setResumeToUpload] = useState<File | null>(null);
  const [showConfirmUploadModal, setShowConfirmUploadModal] = useState(false);

  const handleEditProfileField = () => {
    setShowAthleteDetailsForm(true);
  };

  const { summarySection, workExperienceSection, topRowFlexSections, theRestFlexSections, athleticsSection, educationSection } = useMemo(() => {
    const summarySection = sections.find((section) => section.type === "summary");
    const workExperienceSection = sections.find((section) => section.type === "work_experience");
    const athleticsSection = sections.find((section) => section.type === "athletics");
    const educationSection = sections.find((section) => section.type === "education");

    // First prepare sections by filtering out special sections
    const filteredSections = sections.filter(section =>
      section.type !== "summary" && section.type !== "work_experience" && section.type !== "athletics" && section.type !== "education"
    );

    // Split into filled and empty sections
    const filledSections = filteredSections.filter(section => section.content?.trim() !== "");
    const emptySections = filteredSections.filter(section => section.content?.trim() === "");

    // Group the filled sections first, then empty sections separately
    const filledRowGroupedSections = groupFlexSectionsIntoRows(filledSections);
    const emptyRowGroupedSections = groupFlexSectionsIntoRows(emptySections);

    // Take the first row for top sections (if it exists in filled sections)
    const topRowFlexSections = filledRowGroupedSections[0] || [];

    // The rest of filled sections followed by empty sections
    const theRestFlexSections = [
      ...filledRowGroupedSections.slice(1),
      ...emptyRowGroupedSections
    ];

    return {
      summarySection,
      workExperienceSection,
      topRowFlexSections,
      theRestFlexSections,
      athleticsSection,
      educationSection,
    };
  }, [sections]);

  const minimumSectionsComplete =
    sections.find((section) => section.type === "summary")?.isComplete &&
    sections.find((section) => section.type === "work_experience")?.isComplete &&
    sections.find((section) => section.type === "athletics")?.isComplete &&
    sections.find((section) => section.type === "education")?.isComplete;

  const showOnboardingHelper = isOnboarding || !minimumSectionsComplete;
  // if user info does not exist, navigate to onboarding
  useEffect(() => {
    if (!isSuccessAthleteProfile) return;
    if (athleteProfile && athleteProfile?.displayName === "") {
      navigate(RoutePath.ATHLETE_ONBOARDING);
    }
  }, [athleteProfile, isFetchingAthleteProfile, isSuccessAthleteProfile]);

  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (hasUnsavedChanges) {
        e.preventDefault();
        return "";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, [hasUnsavedChanges]);

  // Check if sections have unsaved changes compared to athleteProfile
  const checkForUnsavedChanges = useCallback(() => {
    if (!athleteProfile || !sections.length) return false;

    const hasChanges = sections.some((section) => {
      // Skip sections with empty content
      if (!section.content?.trim()) return false;

      const originalSection = athleteProfile.sections.sort((a, b) => b.id - a.id).find((s) => s.type === section.type);
      const hasChange = originalSection?.content?.trim() !== section.content?.trim();

      return hasChange;
    });

    // Also check if there are new sections that didn't exist in the original profile
    const hasNewSections = sections.some(
      (section) => !athleteProfile.sections.find((s) => s.type === section.type)
    );


    return hasChanges || hasNewSections;
  }, [sections, athleteProfile]);

  // Set unsaved changes flag when sections or athleteProfile changes
  useEffect(() => {
    if (!athleteProfile || !sections.length) return;
    setHasUnsavedChanges(checkForUnsavedChanges());
  }, [sections, athleteProfile, checkForUnsavedChanges]);

  // First define handleOnboardingComplete
  function handleOnboardingComplete() {
    toast.close("save-all-toast");
    setIsOnboarding(false);
  }

  // Then define handleAllContentSave
  const handleAllContentSave = useCallback(() => {
    // Always use the current sections from the ref
    const currentSections = sectionsRef.current;

    const profile = {
      sections: currentSections.map((section) => ({
        ...(section.id ? { id: section.id } : {}),
        updates: section,
      })),
    };

    updateAthleteProfile({ profile });
    setHasUnsavedChanges(false);
    toast.closeAll();
    handleOnboardingComplete();
  }, [updateAthleteProfile, toast]);

  // Then the useEffect that uses it
  useEffect(() => {
    const toastId = "save-all-toast";

    if (isOnboarding && athleteResumeData && hasUnsavedChanges) {
      if (!toast.isActive(toastId)) {
        toast({
          id: toastId,
          position: "top",
          duration: null,
          render: () => ToastSaveAllComponent(
            handleAllContentSave,
            handleDiscardAll
          ),
        });
      }
    }
  }, [isOnboarding, athleteResumeData, hasUnsavedChanges, toast, handleAllContentSave, handleDiscardAll]);

  function handleDiscardAll() {
    if (!athleteProfile?.sections) return;

    const resetSections = athleteProfile.sections.map((section) => ({
      ...section,
      isNew: false,
      isUpdating: false,
      isEmpty: !section.content || section.content?.trim() === "",
      isComplete: section.content?.trim() !== "",
    }));

    const emptySections = emptySectionStarter.map((section) => ({
      ...section,
      isNew: false,
      isUpdating: false,
      isEmpty: true,
      isComplete: false,
    }));

    const mergedSections = mergeSections(emptySections, resetSections, []);
    setSections(mergedSections);
    setAthleteResumeData(null);
    handleOnboardingComplete();
    setHasUnsavedChanges(false);
  }

  // get messages
  const { data: conversations, refetch: refetchConversations } = useQuery({
    queryKey: ["conversations"],
    queryFn: () => MessagesApi.getUserConversations(athleteProfile!.userId),
  });

  useEffect(() => {
    if (!athleteProfile) return;
    listenToUserConversations(athleteProfile!.userId, undefined, refetchConversations, () => {
      refetchConversations();
    });
  }, [conversations, athleteProfile?.userId]);

  useEffect(() => {
    if (!athleteProfile || isFetchingAthleteProfile) return;
    let preppedResumeSections: DashboardProfileSection[] = [];
    if (athleteResumeData && athleteResumeData.sections && athleteResumeData.sections.length > 0) {
      // Filter out resume sections that already exist in the profile
      const uniqueResumeSections = athleteResumeData.sections.filter(
        (resumeSection) =>
          !athleteProfile.sections?.some((profileSection) => profileSection.type === resumeSection.type),
      );

      preppedResumeSections = uniqueResumeSections.map((section) => ({
        ...section,
        isNew: true,
        isUpdating: false,
        isEmpty: !section.content || section.content?.trim() === "",
        isComplete: false,
      }));
    }

    let preppedProfileSections: DashboardProfileSection[] = [];
    if (athleteProfile.sections && athleteProfile.sections.length > 0) {
      preppedProfileSections = athleteProfile.sections.sort((a, b) => a.id - b.id).map((section) => ({
        ...section,
        isNew: false,
        isUpdating: false,
        isEmpty: false,
        isComplete: section.content?.trim() !== "",
      }));
    }

    const emptySections = emptySectionStarter.map((section) => ({
      ...section,
      isNew: false,
      isUpdating: false,
      isEmpty: true,
      isComplete: false,
    }));

    const mergedSections = mergeSections(emptySections, preppedProfileSections, preppedResumeSections);
    setSections(mergedSections);
  }, [athleteResumeData?.sections, athleteProfile?.sections, isFetchingAthleteProfile]);

  const handleSectionSave = (section: DashboardProfileSection) => {
    if (!userInfo) return;
    const updatedSections = sections.map((s) => {
      if (s.type === section.type) {
        return { ...section, isUpdating: true, isComplete: section.content?.trim() !== "" };
      }
      return s;
    });
    setSections(updatedSections);
    const contentToSave: Partial<AthleteContentSection> = {
      type: section.type,
      index: section.index ?? 0,
      content: section.content,
    };
    updateAthleteContent({
      userId: userInfo.id,
      ...(section.id ? { contentId: section.id } : {}),
      content: contentToSave,
    });
  };

  // Add function to check if date is within last 3 days
  const isWithinLastThreeDays = (date: Date) => {
    const threeDaysAgo = new Date();
    const dateToCheck = new Date(date);
    threeDaysAgo.setDate(threeDaysAgo.getDate() - 3);
    return dateToCheck >= threeDaysAgo;
  };

  function renderSections() {
    // Determine if there are any empty sections
    const hasEmptySections = sections.some(section => section.content?.trim() === "");

    // Find the index where empty sections start in theRestFlexSections
    let emptyRowStartIndex = -1;

    // Check each row to find the first row containing ALL empty sections
    for (let i = 0; i < theRestFlexSections.length; i++) {
      if (theRestFlexSections[i].every(section => section.content?.trim() === "")) {
        emptyRowStartIndex = i;
        break;
      }
    }

    return (
      <Grid templateColumns={{ base: "1fr", md: "repeat(3, 1fr)" }} gap={4} w="100%">
        <GridItem colSpan={{ base: 1, md: 3 }}>
          <AthleteProfileCard athleteProfile={athleteProfile} profileImage={profileImage} editMode />
        </GridItem>

        {(linkedInImportData && (!linkedInImportData?.viewedByUser || isWithinLastThreeDays(linkedInImportData?.viewedByUser))) && (
          <GridItem colSpan={{ base: 1, md: 3 }}>

            <Alert
              status={linkedInImportData.completionDate ? "success" : "info"}
              colorScheme={linkedInImportData.completionDate ? "green" : "yellow"}
              borderRadius="xl"
              w="100%"
            >
              <AlertIcon />
              {linkedInImportData.completionDate ? (
                <Text>
                  Your LinkedIn profile has been successfully imported! Your profile information has been updated below.
                </Text>
              ) : (
                <Text>
                  Our team has received your LinkedIn import request and will complete it within 1-2 business days.
                  <br />
                  You can manually add information to your profile below until then.
                </Text>
              )}
            </Alert>
          </GridItem>
        )}

        {showOnboardingHelper && (
          <GridItem colSpan={{ base: 1, md: 3 }}>
            <Box w="100%" bg="brand.100" p={4} rounded="xl" border="2px dashed" borderColor="black">
              <Stack direction={{ base: "column", md: "row" }} justify="space-between" mb={4}>
                <Heading mb={2} size="md" color="black">
                  Welcome to your dashboard!
                </Heading>
              </Stack>
              <Text color="blackAlpha.800">
                Complete these key sections to increase your visibility to recruiters:
                <br />
                <Icon fontSize="1em" as={FaDiamond} mx={1} color="brand.500" position="relative" top="3px" right="3px" />
                Summary
                <br />
                <Icon fontSize="1em" as={FaDiamond} mx={1} color="brand.500" position="relative" top="3px" right="3px" />
                Education
                <br />
                <Icon fontSize="1em" as={FaDiamond} mx={1} color="brand.500" position="relative" top="3px" right="3px" />
                Work Experience
                <br />
                <Icon fontSize="1em" as={FaDiamond} mx={1} color="brand.500" position="relative" top="3px" right="3px" />
                Athletic Experience
                <br />
                <br />
                Sections with gray backgrounds are empty and hidden from recruiters.
              </Text>
              {!isOnboarding && (
                <HStack justify="flex-end">
                  <Button colorScheme="brand" onClick={() => setShowResumeUploadModal(true)}>Fill Profile from Resume</Button>
                </HStack>
              )}
              <Text color="blackAlpha.800" fontStyle="italic" textAlign="right" mt={2} fontSize="xs">
                This help section will disappear once you've completed these sections.
              </Text>
            </Box>
          </GridItem>
        )}

        {summarySection && (
          <GridItem colSpan={{ base: 1, md: 3 }}>
            <AthleteProfileContentCard section={summarySection} onEdit={handleSectionSave} />
          </GridItem>
        )}

        {topRowFlexSections.length > 0 && (
          <>
            {/* below is a hack to force the top row to be full width if there are 2 sections with a width of 2 */}
            {/* todo is rework the layout/determineColSpan to handle this better */}
            {topRowFlexSections.length === 2 &&
              determineColSpan(topRowFlexSections[0]) === 2 &&
              determineColSpan(topRowFlexSections[1]) === 2 ? (
              // Two sections with width 2 - make both full width
              topRowFlexSections.map((section, index) => (
                <GridItem key={section.type + index} colSpan={{ base: 1, md: 3 }}>
                  <AthleteProfileContentCard section={section} onEdit={handleSectionSave} />
                </GridItem>
              ))
            ) : (
              // Normal rendering for all other cases
              topRowFlexSections.map((section, index) => (
                <GridItem
                  key={section.type + index}
                  colSpan={{
                    base: 1,
                    md: topRowFlexSections.length === 1 ? 3 : determineColSpan(section),
                  }}
                >
                  <AthleteProfileContentCard section={section} onEdit={handleSectionSave} />
                </GridItem>
              ))
            )}
          </>
        )}

        {educationSection && (
          <GridItem colSpan={{ base: 1, md: 1 }}>
            <AthleteProfileContentCard section={educationSection} onEdit={handleSectionSave} />
          </GridItem>
        )}

        {athleticsSection && (
          <GridItem colSpan={{ base: 1, md: 2 }}>
            <AthleteProfileContentCard section={athleticsSection} onEdit={handleSectionSave} />
          </GridItem>
        )}

        {workExperienceSection && (
          <GridItem colSpan={{ base: 1, md: 3 }}>
            <AthleteProfileContentCard section={workExperienceSection} onEdit={handleSectionSave} />
          </GridItem>
        )}

        {theRestFlexSections.length > 0 &&
          theRestFlexSections.map((row, rowIndex) => {
            const isEmptyRow = row.every(section => section.content?.trim() === "");

            // Special case: If there are exactly 2 sections in this row and both would have width 2, 
            // make them full width
            const hasTwoWidth2Sections = row.length === 2 &&
              determineColSpan(row[0]) === 2 &&
              determineColSpan(row[1]) === 2;

            return (
              <React.Fragment key={`flex-section-row-${rowIndex}`}>
                {/* Show divider and warning before the first row of ALL empty sections */}
                {emptyRowStartIndex === rowIndex && hasEmptySections && isEmptyRow && (
                  <GridItem colSpan={{ base: 1, md: 3 }} key="empty-sections-divider">
                    <Divider my={4} />
                    <Box bg="yellow.50" p={4} borderRadius="md" mb={4}>
                      <Text color="gray.600" fontWeight="medium" colorScheme="gray" fontStyle="italic" textAlign="center">
                        ⚠️ Empty sections below will be hidden from employers. Complete them to increase your visibility in recruiter searches.
                      </Text>
                    </Box>
                  </GridItem>
                )}

                {/* Render the row of sections with conditional full width */}
                {row.map((section, sectionIndex) => (
                  <GridItem
                    key={section.type + sectionIndex}
                    colSpan={{
                      base: 1,
                      md: (hasTwoWidth2Sections || row.length === 1) ? 3 : determineColSpan(section),
                    }}
                  >
                    <AthleteProfileContentCard section={section} onEdit={handleSectionSave} />
                  </GridItem>
                ))}
              </React.Fragment>
            );
          })}
      </Grid>
    );
  }

  useEffect(() => {
    if (isUpdatingContentSection) {
      setSuppressPageLoading(true);
    }

    setTimeout(() => {
      setSuppressPageLoading(false);
    }, 1100);
  }, [isUpdatingContentSection]);

  // Handle resume reupload process
  const handleResumeUpload = async (file: File) => {
    setIsUploadingResume(true);
    saveResume(file);
    uploadFullResume(file);
    setAthleteResumeData(null);
  };

  // Handle file selection before upload
  const handleFileSelection = async (file: File) => {
    setResumeToUpload(file);
    setShowConfirmUploadModal(true);
    return Promise.resolve();
  };

  // Confirm and proceed with resume upload
  const confirmAndUploadResume = () => {
    if (resumeToUpload) {
      handleResumeUpload(resumeToUpload);
      setShowConfirmUploadModal(false);
    }
  };

  // Cancel resume upload
  const cancelResumeUpload = () => {
    setShowConfirmUploadModal(false);
    setShowResumeUploadModal(false);
    setResumeToUpload(null);
  };

  // Watch for resume upload completion
  useEffect(() => {
    if (reuploadedResumeData) {
      setAthleteResumeData(reuploadedResumeData);
      setIsOnboarding(true);
      setShowResumeUploadModal(false);
      setIsUploadingResume(false);

      // Force unsaved changes to be true to trigger the onboarding helper
      if (reuploadedResumeData.sections) {
        // Delete all current user's athlete profile content
        deleteAthleteContentMutation.mutate(undefined);

        // Map the resume sections to DashboardProfileSection format
        const newSections = reuploadedResumeData.sections.map((section) => ({
          ...section,
          isNew: true,
          isEmpty: !section.content || section.content.trim() === "",
          isComplete: !!(section.content && section.content.trim() !== ""),
        }));

        // Update the sections with the new data
        setSections(newSections);

        // Set unsaved changes to trigger the onboarding helper
        setHasUnsavedChanges(true);
      }
    }
  }, [reuploadedResumeData, sections.length, isUploadingResume]);

  // Add a ref to keep track of the latest sections
  const sectionsRef = useRef<DashboardProfileSection[]>([]);

  // Update ref when sections change
  useEffect(() => {
    sectionsRef.current = sections;
  }, [sections]);

  if (
    (!isSuccessAthleteProfile ||
      !sections ||
      isFetchingAthleteProfile ||
      isUpdatingProfile ||
      isLoadingProfileImage ||
      isFetchingProfileImage) &&
    !suppressPageLoading
  ) {
    let message = "Loading your dashboard...";
    if (isUpdatingProfile) {
      message = "Updating your profile...";
    }
    return (
      <Page>
        <Center h="90vh" flexDirection="column">
          <Heading size="lg">{message}</Heading>
          <Spinner color="brand.500" size="xl" speed=".60s" thickness="4px" />
        </Center>
      </Page>
    );
  }

  if (showAthleteDetailsForm) {
    return <BuildAthleteInfo onClose={() => setShowAthleteDetailsForm(false)} />;
  }

  return (
    <Page>
      <Stack
        direction={{ base: "column-reverse", md: "row" }}
        w="full"
        gap={3}
        alignItems={{ base: "center", md: "flex-start" }}
      >
        {renderSections()}
        {!isOnboarding && (
          <VStack w={{ base: "full", md: "380px" }} h={{ base: "auto", md: "100vh" }} gap={3}>
            <VideoTile
              thumbnailUrl={"steph.png"}
              videoUrl={"https://www.youtube.com/watch?v=KxB62peJNw4?cc_load_policy=1"}
              alt={"Welcome to HCA"}
            />
            {athleteProfile && (
              <ProfileCompletionTile
                profileType="athlete"
                profileData={athleteProfile}
                onEditField={handleEditProfileField}
                onUpdateProfile={() => setShowAthleteDetailsForm(true)}
                onReuploadResume={() => setShowResumeUploadModal(true)}
              />
            )}
            {conversations && <MessageTile conversations={conversations} />}
          </VStack>
        )}
      </Stack>
      <Modal isOpen={showResumeUploadModal} onClose={() => setShowResumeUploadModal(false)} size={["full", "5xl"]} closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Upload New Resume</ModalHeader>
          {!isUploadingResume && <ModalCloseButton />}
          <ModalBody pb={6}>
            {isUploadingResume ? (
              <Center h="200px" flexDirection="column" gap={4}>
                <Heading size="md">Processing your resume...</Heading>
                <Text fontSize="md" color="gray.500">This may take a up to 30 seconds. Do not close this page.</Text>
                <Spinner color="brand.500" size="xl" speed="1.0s" thickness="4px" />
              </Center>
            ) : (
              <>
                <Text>
                  Upload your updated resume to refresh your profile. We'll use AI to extract information and update your profile.
                </Text>
                <Text mb={4} fontSize="sm" color="gray.500">
                  Your resume is confidential and never shared with recruiters.
                </Text>
                <Text mb={4} fontSize="sm" color="red" textAlign="center" verticalAlign="middle">
                  <Icon fontSize="1em" as={IoWarningOutline} mx={1} color="red" position="relative" top="3px" />
                  WARNING: Uploading a new resume will delete your current profile data and replace it with information from your new resume.
                  <br />
                  All existing profile information will be lost.
                </Text>

                <GenericFileUpload onFileUpload={handleFileSelection} />
              </>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>

      {/* Confirmation Modal */}
      <Modal isOpen={showConfirmUploadModal} onClose={cancelResumeUpload} size={["full", "2xl"]} closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm Resume Upload</ModalHeader>
          <ModalBody pb={6}>
            <Text mb={4} fontSize="md" color="red" textAlign="center" verticalAlign="middle">
              <Icon fontSize="1.2em" as={IoWarningOutline} mx={1} color="red" position="relative" top="3px" />
              WARNING: Uploading a new resume will delete your current profile data and replace it with information from your new resume.
              <br />
              All existing profile information will be lost.
            </Text>

            <HStack justifyContent="center" spacing={4} mt={6}>
              <Button colorScheme="gray" onClick={cancelResumeUpload}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={confirmAndUploadResume}>
                Continue
              </Button>
            </HStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Page>
  );
}
