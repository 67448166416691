import { useRef, useState } from "react";

import { Box, IconButton, Tooltip, HStack, CircularProgress, CircularProgressLabel } from "@chakra-ui/react";
import { AthleteInfoForAdmin } from "@shared/athleteModels";
import axios from "axios";
import { TbBrandLinkedin, TbFileText, TbSend } from "react-icons/tb";

import { getEmptyProfileFields, calculateAdminProfileCompletion } from "../../../utils";

enum resumeStatus {
  empty,
  pending,
  ready,
  error,
}

interface ResumeData {
  url: string | null;
  status: resumeStatus;
}

export const ActionButtons: React.FC<AthleteInfoForAdmin> = (data: AthleteInfoForAdmin) => {
  const { userInfo, primaryEmail, linkedInUrl } = data;
  const resumeMeta = userInfo.files.find((file) => file.category === "resume") || undefined;
  const [resumeData, setResumeData] = useState<ResumeData>({ url: null, status: resumeStatus.empty });
  const actionColumnRef = useRef<HTMLDivElement>(null);

  // Calculate profile completion using the utility function
  const profileCompletion = calculateAdminProfileCompletion(data, "athlete");
  const emptyFields = getEmptyProfileFields(data, "athlete");

  // Create tooltip content for incomplete profile sections
  const incompleteTooltip = () => {
    const note = "\n\n Note: Content sections not included in this calculation.";
    if (profileCompletion === 100) return "Profile fields complete!" + note;

    let content = "Missing profile information:";
    if (emptyFields.length > 0) {
      content += "\n• " + emptyFields.map(f => f.label).join("\n• ");
    }

    content += note;
    return content;
  };

  let dynamicIconSize = 32;
  if (actionColumnRef.current) {
    const width = actionColumnRef.current.getBoundingClientRect().width;
    const adjustment = Math.min(10, Math.round((width - 100) / 10));
    dynamicIconSize = Math.max(22, 22 + adjustment);
  }

  const preloadResumeUrl = async () => {
    if (resumeMeta && !resumeData.url) {
      setResumeData({ ...resumeData, status: resumeStatus.pending });
      try {
        const url = await axios.post("/api/file/presigned-open-url", {
          category: "resume",
          remoteFileName: resumeMeta.stored_file_name,
        });
        setResumeData({ url: url.data.presignedUrl, status: resumeStatus.ready });
      } catch (error) {
        setResumeData({ ...resumeData, status: resumeStatus.error });
      }
    }
  };

  const handleResumeClick = async () => {
    if (!resumeMeta || resumeData.status === resumeStatus.error) {
      return;
    }
    if (!resumeData.url && resumeData.status === resumeStatus.pending) {
      await new Promise((resolve) => setTimeout(resolve, 50));
    }
    if (resumeData.url && resumeData.status === resumeStatus.ready) {
      window.open(resumeData.url, "_blank", "noopener,noreferrer");
    }
  };

  return (
    <Box
      display={"flex"}
      width={"full"}
      justifyContent={"space-between"}
      alignItems={"center"}
      onMouseEnter={preloadResumeUrl}
      ref={actionColumnRef}
    >
      <IconButton
        icon={<TbFileText fontSize={dynamicIconSize} />}
        aria-label="Resume"
        isDisabled={!resumeMeta || resumeData.status === resumeStatus.error}
        variant="ghost"
        color={resumeMeta ? "blue.500" : "gray.300"}
        onClick={handleResumeClick}
      />
      <IconButton
        as="a"
        icon={<TbBrandLinkedin fontSize={dynamicIconSize} />}
        aria-label="LinkedIn profile"
        target="_blank"
        rel="noopener noreferrer"
        href={linkedInUrl ? (linkedInUrl.startsWith("http") ? linkedInUrl : `https://${linkedInUrl}`) : undefined}
        isDisabled={!linkedInUrl}
        variant="ghost"
        color={linkedInUrl ? "blue.500" : "gray.300"}
      />
      <IconButton
        icon={<TbSend fontSize={dynamicIconSize - 2} />}
        aria-label="Send Email"
        isDisabled={!primaryEmail}
        variant="ghost"
        color={primaryEmail ? "blue.500" : "gray.300"}
        onClick={() => {
          if (primaryEmail) {
            window.location.href = `mailto:${primaryEmail}`;
          }
        }}
      />
      <Tooltip
        label={incompleteTooltip()}
        placement="top"
        hasArrow
        closeOnClick={false}
        whiteSpace="pre-line"
      >
        <HStack spacing={1}>
          <CircularProgress
            value={profileCompletion}
            color={profileCompletion < 50 ? "red.400" : profileCompletion < 80 ? "orange.400" : "green.400"}
            size={`${dynamicIconSize + 8}px`}
            thickness="10px"
          >
            <CircularProgressLabel fontSize={`${dynamicIconSize * 0.35}px`}>{profileCompletion}%</CircularProgressLabel>
          </CircularProgress>
        </HStack>
      </Tooltip>
    </Box>
  );
};
