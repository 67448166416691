import { useEffect, useState, forwardRef, useImperativeHandle } from "react";

import { ExternalLinkIcon } from "@chakra-ui/icons";
import {
  Box,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Badge,
  Text,
  Button,
  HStack,
  VStack,
  useToast,
  Spinner,
  Flex,
  IconButton,
  Tooltip,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import axios from "axios";

import { AppConfig } from "../../services/app-config-service";

// Enhanced interface with joined data
interface LinkedInImportRequestWithJoins {
  id: number;
  userId: number;
  linkedinUrl: string;
  requestDate: Date;
  status: string;
  adminId?: number;
  completionDate?: Date;
  viewedByUser?: Date;
  createdAt: Date;
  updatedAt: Date;
  athleteDisplayName: string;
  adminEmail?: string;
}

interface LinkedInServiceTabProps {
  isActive: boolean;
}

export const LinkedInServiceTab = forwardRef(({ isActive }: LinkedInServiceTabProps, ref) => {
  const [requests, setRequests] = useState<LinkedInImportRequestWithJoins[]>([]);
  const [loading, setLoading] = useState(false);
  const [showCompleted, setShowCompleted] = useState(false);
  const toast = useToast();
  const queryClient = useQueryClient();

  // To support parent's interface expectation
  useImperativeHandle(ref, () => ({
    exportToCSV: () => {
      toast({
        title: "Export not available",
        description: "CSV export is not supported for LinkedIn Service tab.",
        status: "info",
        duration: 3000,
        isClosable: true,
      });
    },
  }));

  const fetchLinkedInRequests = async () => {
    setLoading(true);
    try {
      const { data } = await axios.get(`${AppConfig.apiUrl}/api/admin/linkedin-import-requests`);
      setRequests(Array.isArray(data) ? data : data.requests || []);
      console.log("Fetched data:", data);
    } catch (error) {
      console.error("Error fetching LinkedIn import requests:", error);
      toast({
        title: "Error",
        description: "Failed to load LinkedIn import requests.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      setRequests([]);
    } finally {
      setLoading(false);
    }
  };

  const updateRequestStatus = async (requestId: number, status: string) => {
    try {
      const { data } = await axios.patch(
        `${AppConfig.apiUrl}/api/admin/linkedin-import-requests/${requestId}`,
        { status }
      );

      if (data.success) {
        fetchLinkedInRequests();
        queryClient.invalidateQueries({ queryKey: ["linkedInRequests"] });
        toast({
          title: "Status updated",
          description: `Request status changed to ${status}`,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        throw new Error("Update failed");
      }
    } catch (error) {
      console.error("Error updating LinkedIn import request status:", error);
      toast({
        title: "Error",
        description: "Failed to update request status.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleAthleteProfileOpen = (userId: number) => {
    // Just open the admin edit page in a new tab
    window.open(`/admin/edit-athlete/${userId}`, '_blank');
  };

  useEffect(() => {
    if (isActive) {
      fetchLinkedInRequests();
    }
  }, [isActive]);

  const getStatusBadge = (status: string) => {
    switch (status.toLowerCase()) {
      case "pending":
      case "requested":
        return <Badge colorScheme="yellow">Pending</Badge>;
      case "in_progress":
        return <Badge colorScheme="blue">In Progress</Badge>;
      case "completed":
        return <Badge colorScheme="green">Completed</Badge>;
      case "failed":
        return <Badge colorScheme="red">Failed</Badge>;
      default:
        return <Badge>{status}</Badge>;
    }
  };

  const formatDate = (dateString: Date | undefined) => {
    if (!dateString) return "N/A";
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  // Get filtered requests based on showCompleted state
  const filteredRequests = showCompleted
    ? requests
    : requests.filter(request => !request.completionDate);

  return (
    <Box p={4}>
      <VStack spacing={6} align="stretch">
        <HStack justify="space-between">
          <Heading size="md">LinkedIn Import Requests</Heading>
          <HStack>
            <Button
              variant="outline"
              onClick={() => setShowCompleted(!showCompleted)}
              colorScheme={showCompleted ? "blue" : "gray"}
            >
              {showCompleted ? "Hide Completed" : "Show Completed"}
            </Button>
            <Button colorScheme="blue" onClick={fetchLinkedInRequests} isLoading={loading}>
              Refresh
            </Button>
          </HStack>
        </HStack>

        {loading ? (
          <Flex justify="center" align="center" minH="200px">
            <Spinner size="xl" />
          </Flex>
        ) : filteredRequests.length === 0 ? (
          <Text>
            {requests.length === 0
              ? "No LinkedIn import requests found."
              : "No incomplete LinkedIn import requests found."}
          </Text>
        ) : (
          <Box overflowX="auto">
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>Athlete</Th>
                  <Th>Request Date</Th>
                  <Th>Status</Th>
                  <Th>Completion Date</Th>
                  <Th>Completed By</Th>
                  <Th>LinkedIn URL</Th>
                  <Th>Actions</Th>
                </Tr>
              </Thead>
              <Tbody>
                {Array.isArray(filteredRequests) && filteredRequests.map((request) => (
                  <Tr key={request.id}>
                    <Td>
                      <HStack spacing={2} align="center">
                        <Tooltip label="Edit Athlete">
                          <IconButton
                            aria-label="Open Athlete Edit Page"
                            icon={<ExternalLinkIcon />}
                            size="sm"
                            colorScheme="blue"
                            variant="outline"
                            onClick={() => handleAthleteProfileOpen(request.userId)}
                          />
                        </Tooltip>
                        <Text fontWeight="medium">{request.athleteDisplayName || "Unknown"}</Text>
                      </HStack>
                    </Td>
                    <Td>{formatDate(request.requestDate)}</Td>
                    <Td>{getStatusBadge(request.status)}</Td>
                    <Td>{request.completionDate ? formatDate(request.completionDate) : "Not completed"}</Td>
                    <Td>{request.adminEmail || ""}</Td>
                    <Td>
                      <a href={request.linkedinUrl} target="_blank" rel="noopener noreferrer">
                        {request.linkedinUrl.substring(0, 30)}
                        {request.linkedinUrl.length > 30 ? "..." : ""}
                      </a>
                    </Td>
                    <Td>
                      <HStack spacing={2}>
                        <Button
                          size="sm"
                          colorScheme="green"
                          onClick={() => updateRequestStatus(request.id, "completed")}
                          isDisabled={!!request.completionDate}
                        >
                          {request.completionDate ? "Completed" : "Mark Complete"}
                        </Button>
                      </HStack>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        )}
      </VStack>
    </Box>
  );
}); 