import { AthleteInfoForAdmin, AthleteProfile } from "@shared/athleteModels";

import { CompanyProfileInfo } from "../../../shared/companyModels";

// TODO: Make this type fancy
export const requiredFields = {
  athlete: {
    firstName: "First Name",
    lastName: "Last Name",
    displayName: "Display Name",
    primaryEmail: "Email",
    primaryPhone: "Phone Number",
    primaryUniversityName: "University",
    primaryMajor: "Major",
    primarySport: "Primary Sport",
    educationLevel: "Education Level",
    graduationYear: "Graduation Year",
    experienceYears: "Years of Experience",
    workPreference: "Work Preference",
    workType: "Work Type",
    currentLocation: "Current Location",
    primaryPosition: "Primary Sport Position",
  },
  employer: {
    companyName: "Company Name",
    websiteUrl: "Company Website",
    tagline: "Company Tagline",
    highlight: "Company Highlight",
    location: "Location",
    industry: "Industry",
    aboutUs: "About",
  },
};

// Required profile content sections that help with recruiter discovery
export const requiredProfileContent = {
  athlete: [
    { type: "summary", label: "Professional Summary" },
    { type: "education", label: "Education" },
    { type: "work_experience", label: "Work Experience" },
    { type: "athletics", label: "Athletic Experience" },
  ],
  employer: [
    { type: "about", label: "About Company" },
    { type: "benefits", label: "Benefits" },
    { type: "culture", label: "Company Culture" },
  ],
};

export const getMissingProfileContent = (
  profileData: AthleteProfile | CompanyProfileInfo,
  profileType: "athlete" | "employer",
): { type: string; label: string }[] => {
  if (!profileData) return [];

  const sections = "sections" in profileData ? profileData.sections : [];
  const requiredSections = requiredProfileContent[profileType];

  return requiredSections.filter(
    (requiredSection) =>
      !sections.some(
        (section: { type: string; content?: string }) =>
          section.type === requiredSection.type && section.content && section.content.trim() !== "",
      ),
  );
};

export const calculateProfileCompletion = (
  profileData: AthleteInfoForAdmin | CompanyProfileInfo | AthleteProfile | undefined,
  profileType: "athlete" | "employer",
): number => {
  if (!profileData) return 0;

  // Calculate completion for profile fields
  const profileFieldsToComplete = Object.keys(profileData).filter((key) => key in requiredFields[profileType]);
  const totalFields = profileFieldsToComplete.length;
  let filledFields = 0;

  for (const key of profileFieldsToComplete) {
    // Use type safety with a more careful approach
    const value = (profileData as Record<string, unknown>)[key];

    // Check if the field is filled based on its type
    if (
      (typeof value === "string" && value.trim() !== "") ||
      (Array.isArray(value) && value.length > 0) ||
      (typeof value === "number" && !isNaN(value) && value > 0) ||
      (!Array.isArray(value) && typeof value === "object" && value !== null) ||
      (typeof value === "boolean" && value === true)
    ) {
      filledFields++;
    }
  }

  // Calculate completion for profile content sections
  const requiredSections = requiredProfileContent[profileType];
  let completedContentSections = 0;
  const totalContentSections = requiredSections.length;

  if ("sections" in profileData && profileData.sections) {
    const sections = profileData.sections;

    for (const requiredSection of requiredSections) {
      const sectionExists = sections.some(
        (section: { type: string; content?: string }) =>
          section.type === requiredSection.type && section.content && section.content.trim() !== "",
      );

      if (sectionExists) {
        completedContentSections++;
      }
    }
  }

  // Calculate total completion percentage
  // Fields count for 60%, content sections count for 40% of the total
  const fieldsWeight = 0.6;
  const contentWeight = 0.4;

  const fieldsCompletion = totalFields > 0 ? (filledFields / totalFields) * fieldsWeight : 0;
  const contentCompletion =
    totalContentSections > 0 ? (completedContentSections / totalContentSections) * contentWeight : 0;

  const totalCompletion = fieldsCompletion + contentCompletion;

  return Math.round(totalCompletion * 100);
};

/**
 * Calculates profile completion for admin view where content sections aren't available.
 * This uses only profile fields for the calculation.
 */
export const calculateAdminProfileCompletion = (
  profileData: AthleteInfoForAdmin | CompanyProfileInfo | undefined,
  profileType: "athlete" | "employer",
): number => {
  if (!profileData) return 0;

  // Calculate completion for profile fields only
  const profileFieldsToComplete = Object.keys(profileData).filter((key) => key in requiredFields[profileType]);
  const totalFields = profileFieldsToComplete.length;
  let filledFields = 0;

  for (const key of profileFieldsToComplete) {
    // Use type safety with a more careful approach
    const value = (profileData as unknown as Record<string, unknown>)[key];

    // Check if the field is filled based on its type
    if (
      (typeof value === "string" && value.trim() !== "") ||
      (Array.isArray(value) && value.length > 0) ||
      (typeof value === "number" && !isNaN(value) && value > 0) ||
      (!Array.isArray(value) && typeof value === "object" && value !== null) ||
      (typeof value === "boolean" && value === true)
    ) {
      filledFields++;
    }
  }

  // Calculate completion percentage (fields only)
  return Math.round((filledFields / totalFields) * 100);
};

export const getEmptyProfileFields = (
  profileData: AthleteInfoForAdmin | CompanyProfileInfo,
  profileType: "athlete" | "employer",
): { field: string; label: string }[] => {
  const emptyFields: { field: string; label: string }[] = [];

  for (const key in requiredFields[profileType]) {
    const value = profileData[key as keyof (AthleteInfoForAdmin | CompanyProfileInfo)];

    // Check if the field is empty based on its type
    if (
      (typeof value === "string" && value.trim() === "") ||
      (Array.isArray(value) && value.length === 0) ||
      (typeof value === "number" && isNaN(value)) ||
      (typeof value === "object" && value === null)
    ) {
      if (requiredFields[profileType]) {
        emptyFields.push({
          field: key,
          label: requiredFields[profileType][key as keyof (typeof requiredFields)[typeof profileType]],
        });
      }
    }
  }

  return emptyFields;
};

export function simpleDebounce<T extends (...args: unknown[]) => void>(func: T, wait: number) {
  let timeout: NodeJS.Timeout | null = null;

  const executedFunction = function (...args: Parameters<T>) {
    const later = () => {
      timeout = null;
      func(...args);
    };
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(later, wait);
  };

  executedFunction.cancel = () => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
  };

  return executedFunction;
}
