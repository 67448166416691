import React from "react";

import { Box, Center, Container, Heading, Text, VStack, Icon } from "@chakra-ui/react";
import { IoMdConstruct } from "react-icons/io";

export const UniversityPlaceholderPage: React.FC = () => {
  return (
    <Container maxWidth="container.md" p={8} height="100%">
      <Center h="80vh">
        <VStack spacing={6} textAlign="center">
          <Icon as={IoMdConstruct} boxSize={16} color="brand.primary" />
          <Heading size="xl">University Dashboard Paused</Heading>
          <Text fontSize="lg">
            We're sorry for any inconvenience. The University Dashboard is currently paused.
            Please check back later for updates.
          </Text>
          <Box>
            <Text fontSize="md" color="gray.500">
              If you have any questions or need assistance, please contact HCA contact.
            </Text>
          </Box>
        </VStack>
      </Center>
    </Container>
  );
};

export default UniversityPlaceholderPage; 