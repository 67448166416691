import { useEffect, useState } from "react";

import { InfoOutlineIcon } from "@chakra-ui/icons";
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Checkbox,
  Flex,
  Text,
  Button,
  useDisclosure,
  Tooltip,
  Icon,
  FormErrorMessage,
} from "@chakra-ui/react";
import { Select as ReactSelect } from "chakra-react-select";
import { Controller, UseFormReturn, Path, FieldValues, useWatch, PathValue } from "react-hook-form";

import MultiselectModal from "./MultiSelectModal";

export enum FormFieldType {
  TEXT = "text",
  NUMBER = "number",
  TEXTAREA = "textarea",
  SELECT = "select",
  CHECKBOX = "checkbox",
  MULTISELECT = "multiselect",
  DATE = "date", // Unused...
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export interface FormFieldConfig<T> {
  label: string;
  tooltip?: string;
  helperText: string;
  type: FormFieldType;
  options?: (string | { id: string; name: string })[];
  accessor?: T extends (infer U)[] ? (data: U) => string : (data: T) => string;
  required?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dataShapeToSave?: (data: string) => any;
}

interface DynamicFormProps<T extends FieldValues> {
  fieldsToEdit: (keyof T)[];
  formData: T;
  formMethods: UseFormReturn<T>;
  formConfig: Record<keyof T, FormFieldConfig<T[keyof T]>>;
  ignoreRequiredFields?: boolean;
  lastFormElementIsSelect?: boolean;
}

const scrubValues: Record<string, string[]> = {
  gpa: ["0"],
  graduationYear: ["0"],
  athleticExperience: ["0"],
  // Add other fields that need to be scrubbed here
};

const formatPhoneNumber = (input: string) => {
  const cleaned = input.replace(/\D/g, "");
  return cleaned.replace(/(\d{3})(\d{1,3})?(\d{1,4})?/, (_, p1, p2, p3) => {
    let result = p1;
    if (p2) result += `-${p2}`;
    if (p3) result += `-${p3}`;
    return result;
  });
};

const DynamicForm = <T extends FieldValues>({
  fieldsToEdit,
  formData,
  formMethods,
  formConfig,
  ignoreRequiredFields = false,
  lastFormElementIsSelect = false,
}: DynamicFormProps<T>) => {
  const { control, setValue, formState: { errors } } = formMethods;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedField, setSelectedField] = useState<string | null>(null);

  const watchedData = useWatch({ control });

  useEffect(() => {
    Object.keys(formData).forEach((key) => {
      const fieldValue = formData[key as keyof T];
      if (scrubValues[key] && fieldValue !== undefined && fieldValue !== null &&
        scrubValues[key].includes(String(fieldValue))) {
        setValue(key as Path<T>, "" as PathValue<T, Path<T>>);
      } else {
        setValue(key as Path<T>, formData[key as keyof T]);
      }
    });
    // Only run this effect once when the component mounts or when formData reference changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(formData)]);

  const handleEditItems = (field: string) => {
    setSelectedField(field);
    onOpen();
  };

  const handleItemsChange = (selectedItems: string[]) => {
    if (selectedField) {
      setValue(selectedField as Path<T>, selectedItems as unknown as T[keyof T]);
    }
    setSelectedField(null);
  };

  const filteredFormConfig = Object.entries(formConfig).filter(([key]) => fieldsToEdit.includes(key as keyof T));
  // Sort the filtered form config by the order of fieldsToEdit
  filteredFormConfig.sort((a, b) => fieldsToEdit.indexOf(a[0] as keyof T) - fieldsToEdit.indexOf(b[0] as keyof T));

  return (
    <Box
      py={2}
      alignItems={"start"}
      minHeight={"300px"}
      {...(lastFormElementIsSelect && { height: fieldsToEdit.length * 80 + 360 })}
    >
      {!ignoreRequiredFields && (
        <Text fontSize="sm" color="blackAlpha.500" as="i">
          * Indicates required
        </Text>
      )}

      {filteredFormConfig.map(([key, config]) => {
        const { label, tooltip, helperText, type, options, required, accessor } = config;
        const fieldHasError = errors[key as string] !== undefined;

        return (
          <FormControl
            key={label}
            isRequired={required && !ignoreRequiredFields}
            isInvalid={fieldHasError}
            mt={4}
          >
            <FormLabel>
              {label}
              {tooltip && (
                <Tooltip label={tooltip} aria-label="A tooltip" placement="top">
                  <span>
                    <Icon as={InfoOutlineIcon} ml={2} color="gray.400" />
                  </span>
                </Tooltip>
              )}
            </FormLabel>
            {type === FormFieldType.TEXT &&
              (label.toLowerCase().includes("phone") ? (
                <Controller
                  name={key as Path<T>}
                  control={control}
                  rules={{
                    pattern: {
                      value: /^\d{3}-\d{3}-\d{4}$/,
                      message: "Phone number must be in the format 123-456-7890",
                    },
                  }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder={helperText}
                      type="tel"
                      maxLength={12}
                      value={formatPhoneNumber(field?.value ?? "")}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const input = e.target.value.replace(/\D/g, "");
                        const formattedInput = formatPhoneNumber(input);
                        field.onChange(formattedInput);
                      }}
                    />
                  )}
                />
              ) : (
                <Controller
                  name={key as Path<T>}
                  control={control}
                  render={({ field }) => <Input {...field} value={field.value ?? ""} placeholder={helperText} />}
                />
              ))}
            {type === FormFieldType.NUMBER && (
              <Controller
                name={key as Path<T>}
                control={control}
                render={({ field }) => (
                  <Input
                    type="text"
                    {...field}
                    value={field.value ?? ""}
                    placeholder={helperText}
                    onChange={(e) => {
                      // Allow empty string, numbers, decimal point
                      const value = e.target.value;
                      // Only allow digits, a single decimal point, and negative sign at the beginning
                      if (value === "" || /^-?\d*\.?\d*$/.test(value)) {
                        field.onChange(value);
                      }
                    }}
                    onBlur={(e) => {
                      // Convert to number or null when losing focus
                      const value = e.target.value;
                      if (value === "" || value === "." || value === "-") {
                        field.onChange(null);
                      } else {
                        field.onChange(parseFloat(value));
                      }
                      if (field.onBlur) field.onBlur();
                    }}
                  />
                )}
              />
            )}
            {type === FormFieldType.TEXTAREA && (
              <Controller
                name={key as Path<T>}
                control={control}
                render={({ field }) => <Textarea {...field} placeholder={helperText} />}
              />
            )}
            {type === FormFieldType.SELECT && (
              <Controller
                name={key as Path<T>}
                control={control}
                render={({ field }) => (
                  <ReactSelect<{ label: string; value: string }, false>
                    options={options?.map((option) => ({
                      label: typeof option === "string" ? option : option.name,
                      value: typeof option === "string" ? option : option.id,
                    }))}
                    getOptionLabel={(option) => option.label}
                    getOptionValue={(option) => option.value}
                    {...field}
                    onChange={(value) => field.onChange(value?.value)}
                    value={
                      field.value
                        ? {
                          label:
                            options?.find(
                              (option): option is { id: string; name: string } =>
                                typeof option !== "string" && Number(option.id) === Number(field.value),
                            )?.name ?? field.value,
                          value: field.value,
                        }
                        : null
                    }
                  />
                )}
              />
            )}
            {type === FormFieldType.CHECKBOX && (
              <Controller
                name={key as Path<T>}
                control={control}
                render={({ field }) => (
                  <Checkbox
                    {...field}
                    isChecked={field.value === true}
                    value={field.value === true ? "true" : "false"}
                    onChange={(e) => field.onChange(e.target.checked)}
                    colorScheme="brand"
                  >
                    {helperText}
                  </Checkbox>
                )}
              />
            )}
            {type === FormFieldType.MULTISELECT && (
              <Controller
                name={key as Path<T>}
                control={control}
                render={({ field }) => (
                  <Box>
                    <Flex gap={3} mb={3} wrap={"wrap"} {...field}>
                      {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                      {watchedData[key]?.map((item: any, index: number) => (
                        <Text key={index} size="sm" p={1} px={4} rounded={"xl"} bg={"lightgrey"}>
                          {accessor ? accessor(item) : item}
                        </Text>
                      ))}
                    </Flex>
                    <Button colorScheme="brand" variant={"outline"} onClick={() => handleEditItems(key)}>
                      {watchedData[key]?.length > 0 ? "Edit Items" : "Add"}
                    </Button>
                  </Box>
                )}
              />
            )}

            {fieldHasError && (
              <FormErrorMessage>
                {errors[key as string]?.message?.toString()}
              </FormErrorMessage>
            )}
          </FormControl>
        );
      })}

      {selectedField && watchedData && (
        <MultiselectModal
          isOpen={isOpen}
          selectedItems={watchedData[selectedField] as T[keyof T]}
          options={(formConfig[selectedField as keyof T].options as string[]) ?? []}
          accessor={formConfig[selectedField as keyof T].accessor}
          dataShapeToSave={formConfig[selectedField as keyof T].dataShapeToSave}
          closeModal={onClose}
          onChange={handleItemsChange}
        />
      )}
    </Box>
  );
};

export default DynamicForm;
