import { Box, Button, Heading, HStack, Text, VStack } from "@chakra-ui/react";

interface LinkedInImportButtonProps {
  isClicked: boolean;
  onClick: () => void;
}

export function LinkedInImportButton({ isClicked, onClick }: LinkedInImportButtonProps) {
  return (
    <Box
      p={4}
      borderRadius="md"
      border="1px solid"
      borderColor={isClicked ? "gray.200" : "transparent"}
      bg={isClicked ? "green.50" : "gray.50"}
      boxShadow={isClicked ? "none" : "md"}
    >
      <VStack spacing={3} align="start">
        <Heading size="sm">
          LinkedIn Import
        </Heading>

        <Text
          color={isClicked ? "green.500" : "gray.600"}
          fontWeight={isClicked ? "medium" : "normal"}
        >
          {isClicked
            ? "Great! Our team will transfer your LinkedIn data within 1-2 business days!"
            : "Opt-in for our team to transfer your LinkedIn data within 1-2 business days."}
        </Text>

        <HStack w="100%">
          <Button
            onClick={onClick}
            colorScheme="linkedin"
            bg={isClicked ? "#0072b1" : "#0075b1"}
            color="white"
            isDisabled={isClicked}
            transform={isClicked ? "translateY(2px)" : "none"}
            boxShadow={isClicked ? "none" : "md"}
            transition="all 0.2s"
          >
            {isClicked ? "Requested" : "Request LinkedIn Import"}
          </Button>

          {
            isClicked && (
              <Button
                onClick={onClick}
                colorScheme="gray"
                variant="outline"
                transform="translateY(2px)"
                boxShadow="none"
                transition="all 0.2s"
              >
                Cancel Import
              </Button>
            )
          }
        </HStack>
      </VStack>
    </Box>
  );
} 