import { useState, useEffect } from "react";

import {
  Center,
  Spinner,
  Text,
  Button,
  Box,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";

import { AthleteProfile } from "../../../../../shared/athleteModels";
import { UserRole } from "../../../../../shared/models";
import { useAppStore } from "../../app-store";
import {
  useSpecificAthleteProfile,
  useAdminUpdateAthleteProfileMutation,
  useAdminUpdateAthleteInfoMutation
} from "../../hooks/useAthleteProfile";
import { BuildAthleteInfo } from "../../pages/onboarding/athlete-onboarding/BuildAthleteInfo";
import { getSpecificProfileImage } from "../../services/file-api";

import { AthletePage } from "./index";

export function AdminEditAthletePage() {
  const navigate = useNavigate();
  const toast = useToast();
  const { userId } = useParams();
  const parsedUserId = Number(userId);
  const [userInfo] = useAppStore((state) => [state.userInfo]);
  const [isValidatingProfile, setIsValidatingProfile] = useState(true);
  const [modifiedAthleteProfile, setModifiedAthleteProfile] = useState<AthleteProfile | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  // Check if user is admin, if not redirect to regular athlete page
  useEffect(() => {
    if (userInfo && userInfo.role !== UserRole.ADMIN) {
      toast({
        title: "Access denied",
        description: "You don't have permission to edit athlete profiles",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      navigate(`/athlete/profile/${userId}`);
    }
  }, [userInfo, navigate, userId, toast]);

  const {
    data: athleteProfile,
    isError: isAthleteProfileError,
    isFetching: isFetchingAthleteProfile,
    isLoading: isLoadingAthleteProfile,
    refetch: refetchAthleteProfile,
  } = useSpecificAthleteProfile(parsedUserId);

  useEffect(() => {
    if (athleteProfile && !modifiedAthleteProfile) {
      setModifiedAthleteProfile(athleteProfile);
    }
  }, [athleteProfile, modifiedAthleteProfile]);

  const {
    data: profileImage,
    isFetching: isProfileImageFetching,
    refetch: refetchProfileImage,
  } = useQuery({
    queryKey: ["specificProfileImage"],
    queryFn: () => getSpecificProfileImage(parsedUserId),
    refetchOnMount: true,
  });

  useEffect(() => {
    if (athleteProfile?.id !== parsedUserId) {
      refetchAthleteProfile();
      refetchProfileImage();
    }
    setIsValidatingProfile(false);
  }, [athleteProfile, parsedUserId]);

  // Use the admin update mutation
  const adminUpdateMutation = useAdminUpdateAthleteProfileMutation(parsedUserId, () => {
    // Success callback function - navigate back to the athlete profile page
    navigate(`/athlete/profile/${userId}`);
  });

  // Use the admin update info mutation
  const adminUpdateInfoMutation = useAdminUpdateAthleteInfoMutation(parsedUserId, () => {
    // Success callback - just close the modal and refetch
    onClose();
    refetchAthleteProfile();
  });

  const handleSaveChanges = () => {
    if (modifiedAthleteProfile) {
      adminUpdateMutation.mutate(modifiedAthleteProfile);
    }
  };

  // This function would be passed down to components that need to update the athlete profile
  const handleProfileUpdate = (updatedProfile: AthleteProfile) => {
    if (!modifiedAthleteProfile) {
      setModifiedAthleteProfile(updatedProfile);
      return;
    }

    // Get existing and new sections
    const existingSectionIds = modifiedAthleteProfile.sections.map(section => section.id);
    const newSections = updatedProfile.sections.filter(section => !existingSectionIds.includes(section.id));

    // If no new sections, just update with the provided profile
    if (newSections.length === 0) {
      setModifiedAthleteProfile(updatedProfile);
      return;
    }

    // Create updated sections array - preserving existing sections and adding new ones
    const mergedSections = [
      ...modifiedAthleteProfile.sections.map(section =>
        updatedProfile.sections.find(s => s.id === section.id) || section
      ),
      ...newSections
    ];

    // Update the profile with the merged sections
    setModifiedAthleteProfile({
      ...updatedProfile,
      sections: mergedSections
    });
  };

  const handleEditProfileDetails = () => {
    onOpen();
  };

  if (isAthleteProfileError) {
    return (
      <Center h="100%">
        <Text color="red.500" fontSize="2xl" fontWeight="bold">
          User does not exist
        </Text>
      </Center>
    );
  }

  if (
    !athleteProfile ||
    !modifiedAthleteProfile ||
    isFetchingAthleteProfile ||
    isLoadingAthleteProfile ||
    isProfileImageFetching ||
    isValidatingProfile
  ) {
    return (
      <Center h="100%">
        <Spinner size={"lg"} />
      </Center>
    );
  }

  return (
    <Box position="relative">
      <Box
        bg="gray.100"
        p={4}
        mb={4}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        position="fixed"
        top="0"
        left="0"
        right="0"
        borderRadius="0"
        zIndex="1100"
        boxShadow="md"
        width="100%"
      >
        <Text fontSize="xl" fontWeight="bold">Admin Edit Mode: {modifiedAthleteProfile.displayName}</Text>
        <Box gap={2} display="flex" alignItems="center" flexWrap="wrap">
          <Button
            colorScheme="gray"
            mr={2}
            onClick={() => navigate(`/athlete/profile/${userId}`)}
            isDisabled={adminUpdateMutation.isPending}
          >
            Cancel
          </Button>
          <Button
            colorScheme="brand"
            onClick={handleEditProfileDetails}
            variant="outline"
            mr={2}
          >
            Edit Profile Details
          </Button>
          <Button
            colorScheme="green"
            onClick={handleSaveChanges}
            isLoading={adminUpdateMutation.isPending}
            loadingText="Saving..."
          >
            Save Content Changes
          </Button>
        </Box>
      </Box>

      {/* Add padding to prevent content from being hidden under the fixed header */}
      <Box pt="80px">
        <AthletePage
          athleteProfile={modifiedAthleteProfile}
          profileImage={profileImage}
          hideEmptySections={false}
          onEdit={handleProfileUpdate}
        />
      </Box>

      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent bg="gray.100">
          <ModalHeader>Edit Profile Details</ModalHeader>
          <ModalCloseButton />
          <ModalBody w="100%">
            {athleteProfile && (
              <BuildAthleteInfo
                onClose={onClose}
                initialData={athleteProfile}
                customMutationFn={adminUpdateInfoMutation.mutate}
                title="Edit Athlete Profile"
                subheading="Update the athlete's basic information"
              />
            )}
            {!athleteProfile && (
              <Center h="200px">
                <Spinner size="lg" />
              </Center>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box >
  );
} 