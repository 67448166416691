import { useRef, useState, useEffect } from "react";

import { Flex, IconButton, useBreakpointValue, Tooltip, CircularProgress, CircularProgressLabel } from "@chakra-ui/react";
import { TbBrandLinkedin, TbFileText, TbSend } from "react-icons/tb";

import { useDebouncedResize } from "../../../hooks/useDebounceResize";
import { getEmptyProfileFields, calculateAdminProfileCompletion } from "../../../utils";
import { AthleteGridData } from "../types/athletes";

interface AthleteActionIconsProps {
  athlete: AthleteGridData;
  direction?: "row" | "column";
}

export const AthleteActionIcons = ({ athlete, direction = "row" }: AthleteActionIconsProps) => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const ref = useRef<HTMLDivElement>(null);
  const [dynamicIconSize, setDynamicIconSize] = useState(isMobile ? 24 : 32);

  // Calculate profile completion using the utility function
  const profileCompletion = calculateAdminProfileCompletion(athlete, "athlete");
  const emptyFields = getEmptyProfileFields(athlete, "athlete");

  // Create tooltip content for incomplete profile sections
  const incompleteTooltip = () => {
    const note = "\n\n Note: Content sections not included in this calculation.";
    if (profileCompletion === 100) return "Profile fields complete!" + note;

    let content = "Missing profile information:";
    if (emptyFields.length > 0) {
      content += "\n• " + emptyFields.map(f => f.label).join("\n• ");
    }

    content += note;
    return content;
  };

  const getDynamicIconSize = () => {
    if (!ref.current) return;
    const iconBoxDimensions = ref.current?.getBoundingClientRect();
    let dynamicIconSize =
      8 + Math.floor((direction === "row" ? iconBoxDimensions?.width : iconBoxDimensions?.height) * 0.1);
    dynamicIconSize = Math.max(dynamicIconSize, 16);
    dynamicIconSize = Math.min(dynamicIconSize, 32);
    setDynamicIconSize(dynamicIconSize);
  };

  // Call getDynamicIconSize on component mount
  useEffect(() => {
    // Use requestAnimationFrame to ensure DOM is fully rendered
    requestAnimationFrame(() => {
      getDynamicIconSize();
    });
  }, []);

  useDebouncedResize(getDynamicIconSize, 150);

  return (
    <Flex
      ref={ref}
      direction={direction}
      justifyContent={direction === "row" ? "space-between" : "center"}
      alignItems={direction === "row" ? "center" : "space-between"}
      {...(direction === "column"
        ? {
          h: "100%",
          py: 1,
        }
        : {
          w: "100%",
          px: 1,
        })}
    >
      <IconButton
        icon={<TbFileText fontSize={dynamicIconSize} />}
        aria-label="Resume"
        isDisabled={!athlete.resumeOpenUrl}
        variant="ghost"
        color={athlete.resumeOpenUrl ? "blue.500" : "gray.300"}
        onClick={() => {
          if (athlete.resumeOpenUrl) {
            window.open(athlete.resumeOpenUrl, "_blank", "noopener,noreferrer");
          }
        }}
      />
      <IconButton
        as="a"
        icon={<TbBrandLinkedin fontSize={dynamicIconSize} />}
        aria-label="LinkedIn profile"
        target="_blank"
        rel="noopener noreferrer"
        href={
          athlete.linkedInUrl
            ? athlete.linkedInUrl.startsWith("http")
              ? athlete.linkedInUrl
              : `https://${athlete.linkedInUrl}`
            : undefined
        }
        isDisabled={!athlete.linkedInUrl}
        variant="ghost"
        color={athlete.linkedInUrl ? "blue.500" : "gray.300"}
      />
      <IconButton
        icon={<TbSend fontSize={dynamicIconSize - 2} />}
        aria-label="Send Email"
        isDisabled={!athlete.primaryEmail}
        variant="ghost"
        color={athlete.primaryEmail ? "blue.500" : "gray.300"}
        onClick={() => {
          if (athlete.primaryEmail) {
            window.location.href = `mailto:${athlete.primaryEmail}`;
          }
        }}
      />
      <Tooltip
        label={incompleteTooltip()}
        placement="top"
        hasArrow
        closeOnClick={false}
        whiteSpace="pre-line"
      >
        <CircularProgress
          alignSelf="center"
          value={profileCompletion}
          color={profileCompletion < 50 ? "red.400" : profileCompletion < 80 ? "orange.400" : "green.400"}
          size={`${dynamicIconSize + 10}px`}
          thickness="10px"
        >
          <CircularProgressLabel fontSize={`${dynamicIconSize * 0.4}px`}>{profileCompletion}%</CircularProgressLabel>
        </CircularProgress>
      </Tooltip>
    </Flex>
  );
};
