import axios from "axios";

import { AppConfig } from "./app-config-service";
import { AthleteContentSection, AthleteInfoUpdate } from "../../../../shared/athleteModels";
import { AthleteInfo } from "../../../../shared/athleteModels";
import { AthleteProfile } from "../../../../shared/athleteModels";
import { PendingAthleteProfile, UserProfileInfo } from "../../../../shared/models";

export const getProfile = async () => {
  const { data } = await axios.get(`${AppConfig.apiUrl}/api/profile`);
  return data as UserProfileInfo;
};

export const getSpecificProfile = async (userId: number) => {
  const { data } = await axios.get(`${AppConfig.apiUrl}/api/profile/${userId}`);
  return data as UserProfileInfo;
};

export const postUserProfile = async (profileInfo: UserProfileInfo) => {
  return await axios.post(`${AppConfig.apiUrl}/api/profile`, profileInfo, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const getPendingProfilesByDomain = async (emailDomain?: string) => {
  const { data } = await axios.get(`${AppConfig.apiUrl}/api/profile/pending`, {
    params: { emailDomain },
  });
  return data as PendingAthleteProfile[];
};

export const copyPendingProfileToUserProfile = async (profileId: number) => {
  return await axios.post(`${AppConfig.apiUrl}/api/profile/pending`, { profileId });
};

export const postAthleteInfo = async (data: {
  userId?: number;
  info: AthleteInfo;
  linkedinImportRequested?: boolean;
}) => {
  const url = data.userId
    ? `${AppConfig.apiUrl}/api/athlete-info/${data.userId}`
    : `${AppConfig.apiUrl}/api/athlete-info`;

  // Pass the import request flag to the backend
  const payload = {
    info: data.info,
    linkedinImportRequested: data.linkedinImportRequested,
  };

  const { data: responseData } = await axios.post(url, payload);
  return responseData;
};

export const postAthleteContent = async (data: {
  userId: number;
  contentId?: number;
  content: Partial<AthleteContentSection>;
}) => {
  const url = `${AppConfig.apiUrl}/api/athlete-content/${data.contentId ? data.contentId : "new"}`;
  const { data: responseData } = await axios.post(url, data.content);
  return responseData;
};

export const postAthleteProfile = async (data: {
  userId?: number;
  profile: {
    info?: AthleteInfoUpdate;
    sections?: { id?: number; updates: Partial<AthleteContentSection> }[];
  };
}) => {
  const url = data.userId
    ? `${AppConfig.apiUrl}/api/athlete-profile/${data.userId}`
    : `${AppConfig.apiUrl}/api/athlete-profile`;
  const { data: responseData } = await axios.post(url, data.profile);
  return responseData;
};

export const getAthleteInfo = async (): Promise<AthleteInfo> => {
  const { data } = await axios.get(`${AppConfig.apiUrl}/api/athlete-info`);
  return data;
};

export const getSpecificAthleteInfo = async (userId: number): Promise<AthleteInfo | null> => {
  try {
    const { data } = await axios.get(`${AppConfig.apiUrl}/api/athlete-info/${userId}`);

    return data;
  } catch (error) {
    console.error(`[getSpecificAthleteInfo] Error fetching athlete info:`, error);
    return null;
  }
};

export const getAthleteProfile = async () => {
  const { data } = await axios.get(`${AppConfig.apiUrl}/api/athlete-profile`);
  return data;
};

export const getSpecificAthleteProfile = async (userId: number): Promise<AthleteProfile> => {
  try {
    const { data } = await axios.get(`${AppConfig.apiUrl}/api/athlete-profile/${userId}`);

    if (!data) {
      throw new Error("No profile data received");
    }

    return data;
  } catch (error) {
    console.error(`Error fetching athlete profile:`, error);
    throw error;
  }
};

export const getAllAthleteInfo = async (): Promise<AthleteInfo[]> => {
  const { data } = await axios.get(`${AppConfig.apiUrl}/api/athlete-info/all`);
  return data;
};

export const deleteAthleteContent = async (userId?: number) => {
  const url = userId
    ? `${AppConfig.apiUrl}/api/athlete-content/delete/${userId}`
    : `${AppConfig.apiUrl}/api/athlete-content/delete`;
  const { data } = await axios.delete(url);
  return data;
};

// Admin-specific endpoint for updating an athlete's profile
export const updateAthleteProfileAsAdmin = async (userId: number, profile: AthleteProfile) => {
  const url = `${AppConfig.apiUrl}/api/athlete-profile/admin`;

  try {
    const { data } = await axios.patch(
      url,
      {
        targetUserId: userId,
        ...profile, // Spread the profile to include info fields
        section: profile.sections, // The endpoint expects "section" instead of "sections"
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );

    // Check if the response contains an error
    if (data.error) {
      throw new Error(data.error);
    }

    return data;
  } catch (error) {
    // Re-throw any Axios errors with more context
    if (axios.isAxiosError(error)) {
      throw new Error(`API Error: ${error.response?.data?.error || error.message}`);
    }
    throw error;
  }
};

// Admin-specific endpoint for updating an athlete's basic info
export const updateAthleteInfoAsAdmin = async (userId: number, info: AthleteInfo) => {
  const url = `${AppConfig.apiUrl}/api/admin/athlete-info/${userId}`;

  try {
    const { data } = await axios.put(url, info, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (error) {
    // Re-throw any Axios errors with more context
    if (axios.isAxiosError(error)) {
      throw new Error(`API Error: ${error.response?.data?.error || error.message}`);
    }
    console.error(`[updateAthleteInfoAsAdmin] Error:`, error);
    throw error;
  }
};
