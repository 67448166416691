import { useEffect, useState } from "react";

import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Center,
  Divider,
  Heading,
  HStack,
  Spinner,
  Text
} from "@chakra-ui/react";
import axios from "axios";

import { useAppStore } from "../../../app-store";
import { GenericFileUpload } from "../../../components/files/GenericFileUpload";
import { HcaLogo } from "../../../components/HcaLogo";
import { Page } from "../../../components/Page";
import { useAthleteProfile } from "../../../hooks/useAthleteProfile";
import useGetResume from "../../../hooks/useGetResume";
import { useResumeUpload } from "../../../hooks/useResumeUpload";

interface GetAthleteResumeProps {
  continueToNextStep: () => void;
  startFromScratch: () => void;
}

const RESUME_PROCESSING_MESSAGES = [
  "Uploading your resume...",
  "Gathering profile details...",
  "Reviewing your summary...",
  "Reviewing your work experience...",
  "Reviewing your education...",
  "Reviewing your athletics...",
  "Reviewing your skills...",
  "Reviewing your certifications...",
  "Reviewing your projects...",
  "Reviewing your awards...",
  "Reviewing your volunteer experience...",
  "Reviewing your hobbies and interests...",
  "Reviewing your references...",
  "Building your dashboard...",
];

export function GetAthleteResume({ continueToNextStep, startFromScratch }: GetAthleteResumeProps) {
  const [messageIndex, setMessageIndex] = useState(0);
  const [instructionMessage, setInstructionMessage] = useState("");
  const { saveResume, uploadFullResume, isUploading, returnedResumeData } = useResumeUpload();
  const [, setAthleteResumeData] = useAppStore((state) => [state.athleteResumeData, state.setAthleteResumeData]);
  const { data: athleteProfile, isSuccess: isSuccessAthleteProfile } = useAthleteProfile();
  const { data: resumeInfo, isSuccess: isSuccessResumeInfo, error: resumeInfoError } = useGetResume();

  useEffect(() => {
    if (!isSuccessAthleteProfile) return;

    if (!athleteProfile) {
      setInstructionMessage("Let's start with your resume and we can automate building your profile.");
    } else {
      setInstructionMessage(
        "We've made some big improvements to the athlete profile. Upload your resume to automate building your updated profile.",
      );
    }
  }, [isSuccessAthleteProfile]);

  useEffect(() => {
    if (!isSuccessResumeInfo) return;

    if (resumeInfo) {
      const fetchResumeFile = async () => {
        try {
          const url = await axios.post("/api/file/presigned-open-url", {
            category: "resume",
            remoteFileName: resumeInfo.remoteFileName,
          });
          const resumeOpenUrl = url.data.presignedUrl;

          // Fetch the file from the presigned URL
          const response = await fetch(resumeOpenUrl);
          const fileBlob = await response.blob();
          const file = new File([fileBlob], resumeInfo.remoteFileName);

          // Call handleUploadResume with the file
          handleUploadResume(file, false);
        } catch (error) {
          console.error("Error fetching resume file", error);
        }
      };

      fetchResumeFile();
    }
  }, [isSuccessResumeInfo, resumeInfo]);

  useEffect(() => {
    if (isUploading) {
      const interval = setInterval(() => {
        setMessageIndex((prevIndex) => (prevIndex + 1) % RESUME_PROCESSING_MESSAGES.length);
      }, 1500);

      return () => clearInterval(interval);
    }
  }, [isUploading]);

  useEffect(() => {
    if (returnedResumeData) {
      setAthleteResumeData(returnedResumeData);
      setMessageIndex(RESUME_PROCESSING_MESSAGES.length - 1);
      setTimeout(() => {
        continueToNextStep();
      }, 1500);
    }
  }, [returnedResumeData]);

  const handleUploadResume = async (file: File, saveToDb: boolean) => {
    if (saveToDb) {
      saveResume(file);
    }
    uploadFullResume(file);
  };

  if (isUploading || returnedResumeData) {
    return (
      <Center h="90vh" flexDirection="column" gap={4}>
        <Heading textAlign="center">{RESUME_PROCESSING_MESSAGES[messageIndex]}</Heading>
        <Spinner color="brand.500" size="xl" speed="1.0s" thickness="4px" />
      </Center>
    );
  }

  if ((!isSuccessResumeInfo && !resumeInfoError) || resumeInfo) {
    return (
      <Center h="90vh" flexDirection="column" gap={4}>
        <Heading textAlign="center">Checking for your resume...</Heading>
        <Spinner color="brand.500" size="xl" speed="1.0s" thickness="4px" />
      </Center>
    );
  }

  return (
    <Page>
      <Alert status="warning" borderRadius="md" colorScheme="yellow">
        <AlertIcon />
        <Text fontWeight="medium">Your resume is 100% confidential and will not be shared with employers or recruiters. It is only used to auto-fill your profile for your convenience.</Text>
      </Alert>
      <Center h="70vh" maxW="800px" flexDirection="column" gap={4}>
        <HcaLogo boxSize={[32, 40]} mb={4} />
        <Heading size={["xl", "2xl"]}>Welcome to HCA!</Heading>
        <Text textAlign="center" fontSize="lg">
          {instructionMessage}
        </Text>
        <Alert status="info" borderRadius="md" colorScheme="brand">
          <AlertIcon />
          <Text fontWeight="medium">Even a partial or outdated resume helps us build your profile.
          </Text>
        </Alert>
        <Box w="100%" mb={4}>
          <GenericFileUpload onFileUpload={(file) => handleUploadResume(file, true)} />
        </Box>

        <HStack w="100%" justify="center" gap={4}>
          <Divider color="blackAlpha.800" colorScheme="black" />
          <Text textAlign="center" fontSize="md">
            or
          </Text>
          <Divider color="blackAlpha.800" colorScheme="black" />
        </HStack>

        <HStack>
          <Button
            size="md"
            colorScheme="black"
            variant="ghost"
            onClick={startFromScratch}
            _hover={{ bg: "red.300" }}
          >
            No Resume, Start Here
          </Button>
        </HStack>
      </Center>
    </Page>
  );
}
