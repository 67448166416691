import { useEffect, useState, useRef } from "react";

import { DownloadIcon } from "@chakra-ui/icons";
import { Tabs, TabList, Tab, TabPanels, TabPanel, useBreakpointValue, Button, HStack, Circle } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";

import { AthletesTab } from "./AthletesTab";
import { CompaniesTab } from "./CompaniesTab";
import { InsightsTab } from "./InsightsTab";
import { LinkedInServiceTab } from "./LinkedInServiceTab";
import { initializeAgGrid } from "../../ag-grid-setup";
import useUrlParameter from "../../hooks/useUrlParameter";
import { AppConfig } from "../../services/app-config-service";

initializeAgGrid();

// Tab mapping for url readability
const tabMapping: string[] = ["athletes", "companies", "insights", "linkedin-service"];
const getTabIndex = (tab: string) => tabMapping.findIndex((tabName) => tabName === tab);

export interface AdminUrlParameters {
  [key: string]: string;
  tab: string;
  university: string;
  athlete: string;
  deepSearch: string;
}

// Interface for LinkedIn request data
interface LinkedInImportRequest {
  id: number;
  userId: number;
  completionDate?: Date | null;
  status: string;
}

export const AdminPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const isMobile = useBreakpointValue({ base: true, md: false });
  const { getAllUrlParameters, clearUrlParameter } = useUrlParameter();
  const athletesTabRef = useRef<{ exportToCSV: () => void }>(null);
  const companiesTabRef = useRef<{ exportToCSV: () => void }>(null);
  const insightsTabRef = useRef<{ exportToCSV: () => void }>(null);

  const allUrlParameters = getAllUrlParameters() as AdminUrlParameters;
  const initialTabIndex = getTabIndex(allUrlParameters.tab || "athletes");
  const [tabIndex, setTabIndex] = useState(initialTabIndex);

  // Query for incomplete LinkedIn profiles
  const { data: linkedInRequestsData } = useQuery({
    queryKey: ["linkedInRequests"],
    queryFn: async () => {
      const { data } = await axios.get(`${AppConfig.apiUrl}/api/admin/linkedin-import-requests`);
      return Array.isArray(data) ? data : data.requests || [];
    },
    // Refetch every 5 minutes and on window focus
    refetchInterval: 5 * 60 * 1000,
    refetchOnWindowFocus: true,
    // Always enabled regardless of which tab is active
    enabled: true,
  });

  // Count incomplete profiles (no completion date)
  const incompleteCount = linkedInRequestsData?.filter(
    (request: LinkedInImportRequest) => !request.completionDate
  )?.length || 0;

  useEffect(() => {
    const currentParams = getAllUrlParameters();
    const currentTab = currentParams.tab;

    if (!currentTab) {
      setTabIndex(0);
      navigate(`?tab=athletes`, { replace: true });
      return;
    }
    if (!tabMapping.includes(currentTab)) {
      clearUrlParameter("tab");
      setTabIndex(0);
      navigate(`?tab=athletes`, { replace: true });
      return;
    }
  }, [tabIndex, navigate, location.search]);

  const handleTabChange = (index: number) => {
    setTabIndex(index);
    navigate(`?tab=${tabMapping[index]}`, { replace: true });
  };

  const handleExportToCSV = () => {
    switch (tabIndex) {
      case 0:
        if (athletesTabRef.current) {
          athletesTabRef.current.exportToCSV();
        }
        break;
      case 1:
        if (companiesTabRef.current) {
          companiesTabRef.current.exportToCSV();
        }
        break;
      case 2:
        if (insightsTabRef.current) {
          insightsTabRef.current.exportToCSV();
        }
        break;
      case 3:
        // LinkedIn Service tab doesn't support CSV export
        break;
      default:
        break;
    }
  };

  return (
    <>
      <HStack justifyContent="flex-end" mb={2} pr={2}>
        <Button
          leftIcon={<DownloadIcon />}
          colorScheme="blue"
          variant="outline"
          onClick={handleExportToCSV}
          size="sm"
        >
          Export Data
        </Button>
      </HStack>
      <Tabs
        index={tabIndex}
        onChange={(index) => handleTabChange(index)}
        isFitted={isMobile}
        position="relative"
        variant="enclosed"
        size={["sm", "lg"]}
      >
        <TabList>
          <Tab>Athletes</Tab>
          <Tab>Companies</Tab>
          <Tab>Insights</Tab>
          <Tab position="relative">
            LinkedIn Service
            {incompleteCount > 0 && (
              <Circle
                size="20px"
                bg="purple.500"
                color="white"
                position="absolute"
                top="-8px"
                right="-8px"
                fontSize="xs"
                fontWeight="bold"
              >
                {incompleteCount}
              </Circle>
            )}
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel px={0}>
            <AthletesTab ref={athletesTabRef} urlParameters={allUrlParameters} />
          </TabPanel>
          <TabPanel px={0}>
            <CompaniesTab ref={companiesTabRef} isActive={tabIndex === 1} />
          </TabPanel>
          <TabPanel px={0}>
            <InsightsTab ref={insightsTabRef} />
          </TabPanel>
          <TabPanel px={0}>
            <LinkedInServiceTab isActive={tabIndex === 3} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  );
};
