import React from "react";

import { EditIcon } from "@chakra-ui/icons";
import {
  Card,
  CardHeader,
  CardBody,
  Heading,
  HStack,
  CircularProgress,
  CircularProgressLabel,
  Button,
  Text,
  VStack,
  Box,
  Divider,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  ButtonGroup,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react";
import { AthleteInfoForAdmin, AthleteProfile } from "@shared/athleteModels";
import { CompanyProfileInfo } from "@shared/companyModels";
import { IoEllipsisVerticalSharp } from "react-icons/io5";

import { calculateProfileCompletion, getEmptyProfileFields, getMissingProfileContent } from "../../utils";

interface ProfileCompletionTileProps {
  profileType: "athlete" | "employer";
  profileData: AthleteInfoForAdmin | CompanyProfileInfo | AthleteProfile;
  onEditField: (fields: string[]) => void;
  onUpdateProfile?: () => void;
  onReuploadResume?: () => void;
}

const ProfileCompletionTile: React.FC<ProfileCompletionTileProps> = ({
  profileType,
  profileData,
  onEditField,
  onUpdateProfile,
  onReuploadResume
}) => {
  const [showMoreFields, setShowMoreFields] = React.useState(false);
  const [isMobile, setIsMobile] = React.useState(false);
  const [listMaxCount, setListMaxCount] = React.useState(4);
  const [showTips, setShowTips] = React.useState(false);

  React.useEffect(() => {
    setIsMobile(window.innerWidth < 768);
  }, []);

  React.useEffect(() => {
    if (isMobile) {
      setListMaxCount(2);
    } else {
      setListMaxCount(4);
    }
  }, [isMobile]);

  let completionColor = "brand.300";

  const profileCompletion = calculateProfileCompletion(profileData as AthleteInfoForAdmin | CompanyProfileInfo, profileType);
  const missingFields = getEmptyProfileFields(profileData as AthleteInfoForAdmin | CompanyProfileInfo, profileType);
  const missingContentSections = 'sections' in profileData
    ? getMissingProfileContent(profileData as AthleteProfile | CompanyProfileInfo, profileType)
    : [];

  const incompleteCount = 'sections' in profileData && profileData.sections
    ? profileData.sections.filter((section: { content?: string }) => !section.content || section.content.trim() === '').length
    : 0;

  if (profileCompletion < 40) {
    completionColor = "red.400";
  } else if (profileCompletion < 80) {
    completionColor = "yellow.400";
  }

  const ProfileComplete = () => {
    return (
      <Card size={["md", "lg"]} w="full" border="2px solid" borderColor="brand.500">
        <CardHeader paddingY={4} w="full">
          <VStack w="full" justifyContent={"space-between"}>
            <Heading size="md" color="brand.500" textAlign="left" alignSelf="flex-start">
              Boost Your Visibility
            </Heading>
            <ButtonGroup>
              <Button
                variant="outline"
                colorScheme="brand"
                border="2px solid"
                onClick={() => setShowTips(!showTips)}
              >
                Tips
              </Button>
              {profileType === "athlete" && onUpdateProfile && (
                <Menu>
                  <MenuButton as={Button} variant="solid" colorScheme="brand" rightIcon={<IoEllipsisVerticalSharp />}>
                    Update Profile
                  </MenuButton>
                  <MenuList>
                    <MenuItem onClick={onUpdateProfile}>
                      Update Profile Details
                    </MenuItem>
                    {onReuploadResume && (
                      <MenuItem onClick={onReuploadResume}>
                        Reupload Resume
                      </MenuItem>
                    )}
                  </MenuList>
                </Menu>
              )}
            </ButtonGroup>
          </VStack>
        </CardHeader>
        {showTips && (
          <CardBody pt={0}>
            <UnorderedList mt={0} spacing={1}>
              <ListItem>
                <Text>
                  <b>Keep location current.</b> Helps recruiters find you for regional opportunities.
                </Text>
              </ListItem>
              <ListItem>
                <Text>
                  <b>Use industry keywords.</b> Makes your profile appear in recruiter searches.
                </Text>
              </ListItem>
              <ListItem>
                <Text>
                  <b>Complete all sections.</b> With {incompleteCount} incomplete sections. Gray areas are hidden from recruiters.
                </Text>
              </ListItem>
              <ListItem>
                <Text>
                  <b>Reply promptly.</b> Quick responses show your professionalism.
                </Text>
              </ListItem>
            </UnorderedList>
          </CardBody>
        )}
      </Card>
    );
  };

  if (profileCompletion === 100) {
    return <ProfileComplete />;
  }

  return (
    <Card size={["md", "lg"]} w="full">
      <CardHeader paddingTop={2} paddingBottom={2} w="full">
        <HStack w="full" justifyContent={"space-between"}>
          <Heading size={["md"]}>Suggested Steps</Heading>
          <CircularProgress value={profileCompletion} color={completionColor} capIsRound>
            <CircularProgressLabel fontSize="xs" as="b">
              {profileCompletion}%
            </CircularProgressLabel>
          </CircularProgress>
        </HStack>
      </CardHeader>

      <CardBody paddingTop={0}>
        <VStack spacing={2} align="stretch">
          {missingContentSections.length > 0 && (
            <>
              <Box w="full" py={2}>
                <Text fontSize="sm" fontWeight="medium" mb={1}>Required for recruiter discovery:</Text>
                <VStack spacing={2} align="stretch">
                  {missingContentSections.map((section) => (
                    <Box
                      key={section.type}
                      p={2.5}
                      bg="red.50"
                      borderRadius="md"
                      borderLeft="3px solid"
                      borderColor="red.300"
                    >
                      <Text fontSize="sm" color="gray.700">
                        {section.label}
                      </Text>
                    </Box>
                  ))}
                </VStack>
              </Box>
              <Divider />
            </>
          )}

          {/* Update Profile Menu Button */}
          {profileType === "athlete" && onUpdateProfile && (
            <Menu>
              <MenuButton as={Button} variant="solid" colorScheme="brand" rightIcon={<IoEllipsisVerticalSharp />} w="full">
                Update Profile
              </MenuButton>
              <MenuList>
                <MenuItem onClick={onUpdateProfile}>
                  Update Profile Details
                </MenuItem>
                {onReuploadResume && (
                  <MenuItem onClick={onReuploadResume}>
                    Reupload Resume
                  </MenuItem>
                )}
              </MenuList>
            </Menu>
          )}

          {missingFields.slice(0, showMoreFields ? undefined : listMaxCount).map((missingField) => (
            <Button
              onClick={() => onEditField([missingField.field])}
              key={missingField.field}
              w="full"
              rightIcon={<EditIcon boxSize={4} />}
              colorScheme="brand"
              variant="outline"
              justifyContent="space-between"
            >
              <Text fontSize="sm" noOfLines={1}>
                {missingField.label}
              </Text>
            </Button>
          ))}
          {missingFields.length > listMaxCount && (
            <Button
              onClick={() => setShowMoreFields(!showMoreFields)}
              w="full"
              colorScheme="accent"
              variant="outline"
              justifyContent="center"
            >
              <Text fontSize="sm">{showMoreFields ? "Show less" : `+${missingFields.length - listMaxCount} more`}</Text>
            </Button>
          )}
        </VStack>
      </CardBody>
    </Card>
  );
};

export default ProfileCompletionTile;
