import { useState, useMemo } from "react";


import { ChatIcon, InfoIcon, EditIcon } from "@chakra-ui/icons";
import {
  Card,
  Box,
  CardHeader,
  Heading,
  CardBody,
  VStack,
  HStack,
  Text,
  Stack,
  Flex,
  Button,
  Hide,
  Tooltip,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import { AthleteProfile } from "../../../../../shared/athleteModels";
import { UserRole } from "../../../../../shared/models";
import { useAppStore } from "../../app-store";
import { EditableAvatar } from "../../components/EditableAvatar";
import MessagingModal from "../../components/MessagingModal";
import { MessagesApi } from "../../services/messages-api";
import { getMissingProfileContent } from "../../utils";

interface AthleteProfileCardProps {
  athleteProfile?: AthleteProfile;
  profileImage?: { url: string } | null;
  editMode?: boolean;
  isAdmin?: boolean;
}

const AthleteProfileCard: React.FC<AthleteProfileCardProps> = ({ athleteProfile, profileImage, editMode = false, isAdmin = false }) => {
  const navigate = useNavigate();
  const [userInfo] = useAppStore((state) => [state.userInfo]);

  const [isMessagingModalOpen, setIsMessagingModalOpen] = useState(false);

  const { data: conversation } = useQuery({
    queryKey: ["conversation", userInfo?.id, athleteProfile?.userId],
    queryFn: () => MessagesApi.getConversationByParticipants(userInfo?.id ?? 0, athleteProfile?.userId ?? 0),
    enabled: !!userInfo?.id && !!athleteProfile?.userId && userInfo.id !== athleteProfile.userId,
  });

  const isRecruiter = userInfo?.role === UserRole.RECRUITER || userInfo?.role === UserRole.COMPANY_ADMIN;
  const isAthlete = userInfo?.role === UserRole.ATHLETE;
  const canMessage = (isRecruiter || userInfo?.role === UserRole.ADMIN) && userInfo?.id !== athleteProfile?.userId;

  // Check if there are missing content sections
  const missingContentSections = athleteProfile ? getMissingProfileContent(athleteProfile, "athlete") : [];
  const hasCompletedProfile = missingContentSections.length === 0;

  if (!athleteProfile) {
    return null;
  }

  let profileName = athleteProfile?.displayName ? athleteProfile?.displayName : "Athlete";

  if (isRecruiter) {
    profileName = athleteProfile?.displayName ? athleteProfile?.displayName.split(" ")[0] : "Athlete";
  }

  const summaryPreview = useMemo(() => {
    const summarySection = athleteProfile?.sections?.find((section) => section.type === "summary");
    return summarySection?.content?.trim() || "No summary available";
  }, [athleteProfile?.sections]);

  const handleEditProfile = () => {
    if (athleteProfile?.userId) {
      navigate(`/admin/edit-athlete/${athleteProfile.userId}`);
    }
  };

  return (
    <>
      <Card size="lg" w="full" overflow={"hidden"}>
        <Box bg="blackAlpha.300" w="full" h={["60px", 140, 140]} alignContent={"flex-end"} px={6}>
          <HStack justifyContent={"space-between"}>
            <EditableAvatar imageUrl={profileImage?.url} editMode={editMode} />
          </HStack>
        </Box>
        <CardHeader paddingBottom={1} paddingTop={16}>
          <VStack alignItems={"flex-start"}>
            <Stack justifyContent={"space-between"} w="full" direction={["column", "row"]}>
              <Stack direction={["column", "row"]} alignItems="flex-start">
                <Heading size="lg">{profileName}</Heading>
                <HStack>
                  {/* Show appropriate status based on profile completeness and user role */}
                  {(isAthlete || hasCompletedProfile) && (
                    <Text fontSize="24px" fontWeight="bold" color={hasCompletedProfile ? "brand.primary" : "red.400"} mr={3} position="relative">
                      <Hide below="md">
                        <Text fontSize="24px" fontWeight="normal" color={"black"} mx={3} as="span">
                          |
                        </Text>
                      </Hide>
                      <Box as="span" display="inline-flex" alignItems="center">
                        {hasCompletedProfile ? "Recruit Me" : "Incomplete Profile"}
                        {isAthlete && (
                          <Tooltip
                            label={hasCompletedProfile
                              ? "Your profile content is ready to be found by recruiters."
                              : "You have incomplete sections in your profile. See the 'Suggested Steps' list for help."}
                            placement="top"
                            bg="gray.700"
                            color="white"
                            fontSize="sm"
                            hasArrow
                          >
                            <InfoIcon
                              boxSize={4}
                              color="gray.500"
                              ml={2}
                              cursor="pointer"
                              verticalAlign="middle"
                            />
                          </Tooltip>
                        )}
                      </Box>
                    </Text>
                  )}
                  {hasCompletedProfile && (
                    <Text fontSize="22px" color={"gray.500"} fontStyle="italic" fontWeight="normal">
                      {athleteProfile.workPreference}
                    </Text>
                  )}
                </HStack>
              </Stack>

              <Flex h="100%" justifyContent={"flex-end"} alignItems={"flex-start"} flexDirection={["column", "row"]} gap={2}>
                {isAdmin && (
                  <Button
                    leftIcon={<EditIcon fontSize={18} color="brand.primary" />}
                    bg="gray.100"
                    size="lg"
                    p={4}
                    _hover={{ border: "1px solid", borderColor: "brand.primary" }}
                    onClick={handleEditProfile}
                  >
                    <Text fontSize={16} color="slate.500" mr={2}>
                      Edit Profile
                    </Text>
                  </Button>
                )}
                {canMessage && (
                  <Button
                    leftIcon={<ChatIcon fontSize={24} color="brand.primary" />}
                    bg="gray.100"
                    size="lg"
                    p={4}
                    _hover={{ border: "1px solid", borderColor: "brand.primary" }}
                    onClick={() => setIsMessagingModalOpen(true)}
                  >
                    <Text fontSize={18} color="slate.500" mr={2}>
                      Message
                    </Text>
                  </Button>
                )}
              </Flex>
            </Stack>

            <Text
              size="sm"
              color="slate.500"
              fontWeight="normal"
              noOfLines={2}
              overflow="hidden"
              textOverflow="ellipsis"
              w="full"
              minW={0}
              pr={{ base: 2, md: 5 }}
            >
              {summaryPreview}
            </Text>
          </VStack>
        </CardHeader>
        <CardBody paddingTop={1}>
          <VStack gap={0} w="full" alignItems="flex-start">
            <Stack direction={["column", "row"]} w="full">
              {/* <Text fontSize="20px" fontWeight="semibold" color={"blackAlpha.700"}>
                What about a mini description or tagline or what kind of work they are looking for?
              </Text> */}
            </Stack>
          </VStack>
        </CardBody>
      </Card>
      <MessagingModal
        isOpen={isMessagingModalOpen}
        onClose={() => setIsMessagingModalOpen(false)}
        recipient={{
          recipientId: athleteProfile.userId,
          recipientName: profileName,
        }}
        conversation={conversation ?? null}
      />
    </>
  );
};

export default AthleteProfileCard;
