import { useEffect, useState } from "react";

import { Center, Spinner, Text } from "@chakra-ui/react";
import { UserRole } from "@shared/models";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

import { useSpecificAthleteProfile } from "../../hooks/useAthleteProfile";
import { getSpecificProfileImage } from "../../services/file-api";

import { AthletePage } from "./index";

import { useAppStore } from "~/app-store";

export function SpecificAthleteProfilePage() {
  const [isValidatingProfile, setIsValidatingProfile] = useState(true);
  const { userId } = useParams();
  const [userInfo] = useAppStore((state) => [state.userInfo]);

  const isAdmin = userInfo?.role === UserRole.ADMIN;


  const {
    data: athleteProfile,
    isError: isAthleteProfileError,
    isFetching: isFetchingAthleteProfile,
    isLoading: isLoadingAthleteProfile,
    refetch: refetchAthleteProfile,
  } = useSpecificAthleteProfile(Number(userId));

  const {
    data: profileImage,
    isFetching: isProfileImageFetching,
    refetch: refetchProfileImage,
  } = useQuery({
    queryKey: ["specificProfileImage"],
    queryFn: () => getSpecificProfileImage(Number(userId)),
    refetchOnMount: true,
  });

  useEffect(() => {
    if (athleteProfile?.id !== Number(userId)) {
      refetchAthleteProfile();
      refetchProfileImage();
    }
    setIsValidatingProfile(false);
  }, [athleteProfile, userId]);

  if (isAthleteProfileError) {
    return (
      <Center h="100%">
        <Text color="red.500" fontSize="2xl" fontWeight="bold">
          User does not exist
        </Text>
      </Center>
    );
  }

  if (
    !athleteProfile ||
    isFetchingAthleteProfile ||
    isLoadingAthleteProfile ||
    isProfileImageFetching ||
    isValidatingProfile
  ) {
    return (
      <Center h="100%">
        <Spinner size={"lg"} />
      </Center>
    );
  }

  return <AthletePage athleteProfile={athleteProfile} profileImage={profileImage} hideEmptySections={true} showEdit={isAdmin} />;
}
