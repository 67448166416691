import { useToast } from "@chakra-ui/react";
import { useMutation, useQuery, useQueryClient, UseQueryResult } from "@tanstack/react-query";

import { AthleteProfile, AthleteInfo } from "../../../../shared/athleteModels";
import {
  postAthleteContent,
  postAthleteInfo,
  postAthleteProfile,
  getAthleteInfo,
  getSpecificAthleteInfo,
  getAthleteProfile,
  getSpecificAthleteProfile,
  getAllAthleteInfo,
  deleteAthleteContent,
  updateAthleteProfileAsAdmin,
  updateAthleteInfoAsAdmin,
} from "../services/athlete-api";

export const useAthleteInfo = () => {
  return useQuery({
    queryKey: ["athleteInfo"],
    queryFn: () => getAthleteInfo(),
  });
};

export const useAllAthleteInfo = () => {
  return useQuery({
    queryKey: ["allAthleteInfo"],
    queryFn: () => getAllAthleteInfo(),
  });
};

export const useSpecificAthleteInfo = (userId: number) => {
  return useQuery({
    queryKey: ["athleteInfo", userId],
    queryFn: () => getSpecificAthleteInfo(userId),
  });
};

export const useAthleteProfile = (): UseQueryResult<AthleteProfile> => {
  return useQuery({
    queryKey: ["athleteProfile"],
    queryFn: () => getAthleteProfile(),
  });
};

export const useSpecificAthleteProfile = (userId: number) => {
  return useQuery({
    queryKey: ["athleteProfile", userId],
    queryFn: () => getSpecificAthleteProfile(userId),
  });
};

export const useAthleteInfoMutation = (onSuccessCallback?: () => void) => {
  const queryClient = useQueryClient();
  const toast = useToast();

  return useMutation({
    mutationFn: (data: { userId?: number; info: AthleteInfo; linkedinImportRequested?: boolean }) =>
      postAthleteInfo(data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["athleteInfo"] });
      queryClient.invalidateQueries({ queryKey: ["athleteProfile"] });
      toast({
        title: "Success",
        description: "Profile details updated successfully",
        status: "success",
        position: "top",
        duration: 3000,
      });
      if (onSuccessCallback) {
        onSuccessCallback();
      }
    },
    onError: (error) => {
      toast({
        title: "Error",
        description: "Failed to update profile details",
        status: "error",
        position: "top",
        duration: 5000,
      });
      console.error("Error updating athlete info:", error);
    },
  });
};

export const useAthleteContentMutation = () => {
  const queryClient = useQueryClient();
  const toast = useToast();

  return useMutation({
    mutationFn: postAthleteContent,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["athleteProfile"] });
      toast({
        title: "Success",
        description: "Profile changes saved",
        status: "success",
        position: "top",
        duration: 3000,
      });
    },
    onError: (error) => {
      toast({
        title: "Error",
        description: "Failed to update profile",
        status: "error",
        position: "top",
        duration: 5000,
      });
      console.error("Error updating athlete content:", error);
    },
  });
};

export const useAthleteProfileMutation = () => {
  const queryClient = useQueryClient();
  const toast = useToast();

  return useMutation({
    mutationFn: postAthleteProfile,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["athleteInfo"] });
      queryClient.invalidateQueries({ queryKey: ["athleteProfile"] });
      toast({
        title: "Success",
        description: "Profile updated successfully",
        status: "success",
        position: "top",
        duration: 3000,
      });
    },
    onError: (error) => {
      toast({
        title: "Error",
        description: "Failed to update profile",
        status: "error",
        position: "top",
        duration: 5000,
      });
      console.error("Error updating athlete profile:", error);
    },
  });
};

export const useDeleteAthleteContentMutation = (onSuccessCallback?: () => void) => {
  const queryClient = useQueryClient();
  const toast = useToast();

  return useMutation({
    mutationFn: deleteAthleteContent,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["athleteProfile"] });
      if (onSuccessCallback) {
        onSuccessCallback();
      }
    },
    onError: (error) => {
      toast({
        title: "Error",
        description: "Failed to delete profile content",
        status: "error",
        position: "top",
        duration: 5000,
      });
      console.error("Error deleting athlete content:", error);
    },
  });
};

// Admin-specific mutation hook for updating any athlete's profile
export const useAdminUpdateAthleteProfileMutation = (userId: number, onSuccessCallback?: () => void) => {
  const queryClient = useQueryClient();
  const toast = useToast();

  return useMutation({
    mutationFn: (updatedProfile: AthleteProfile) => updateAthleteProfileAsAdmin(userId, updatedProfile),
    onSuccess: () => {
      // Invalidate both the specific profile and all athlete info
      queryClient.invalidateQueries({ queryKey: ["athleteProfile", userId] });
      queryClient.invalidateQueries({ queryKey: ["allAthleteInfo"] });

      toast({
        title: "Success",
        description: "Athlete profile updated successfully",
        status: "success",
        position: "top",
        duration: 3000,
      });

      if (onSuccessCallback) {
        onSuccessCallback();
      }
    },
    onError: (error) => {
      toast({
        title: "Error",
        description: `Failed to update athlete profile: ${error instanceof Error ? error.message : "Unknown error"}`,
        status: "error",
        position: "top",
        duration: 5000,
      });
      console.error("Error updating athlete profile as admin:", error);
    },
  });
};

// Admin-specific mutation hook for updating any athlete's basic info
export const useAdminUpdateAthleteInfoMutation = (userId: number, onSuccessCallback?: () => void) => {
  const queryClient = useQueryClient();
  const toast = useToast();

  return useMutation({
    mutationFn: (data: { info: AthleteInfo }) => updateAthleteInfoAsAdmin(userId, data.info),
    onSuccess: () => {
      // Invalidate both the specific profile and info
      queryClient.invalidateQueries({ queryKey: ["athleteProfile", userId] });
      queryClient.invalidateQueries({ queryKey: ["athleteInfo", userId] });
      queryClient.invalidateQueries({ queryKey: ["allAthleteInfo"] });

      toast({
        title: "Success",
        description: "Athlete info updated successfully",
        status: "success",
        position: "top",
        duration: 3000,
      });

      if (onSuccessCallback) {
        onSuccessCallback();
      }
    },
    onError: (error) => {
      toast({
        title: "Error",
        description: `Failed to update athlete info: ${error instanceof Error ? error.message : "Unknown error"}`,
        status: "error",
        position: "top",
        duration: 5000,
      });
      console.error("Error updating athlete info as admin:", error);
    },
  });
};
