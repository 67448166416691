import { useEffect, useRef, useState } from "react";

import { Box, Button, Center, Heading, Spinner, Text, VStack, FormErrorMessage, useToast } from "@chakra-ui/react";
import { AthleteInfo, AthleteProfile } from "@shared/athleteModels";
import { useForm, SubmitHandler } from "react-hook-form";

import { useAppStore } from "../../../app-store";
import DynamicForm, { FormFieldConfig, FormFieldType } from "../../../components/DynamicForm";
import { LinkedInImportButton } from "../../../components/LinkedInImportButton";
import { Page } from "../../../components/Page";
import {
  athleteBasicInfoFieldsToEdit,
  athleteFormConfig,
  AthleteInfoEditableFields,
  educationLevelOptions,
  majorOptions,
  preferredEmploymentOptions,
  preferredWorkEnvironmentOptions,
  stateOptions,
} from "../../../config/athleteFormConfig";
import { useAthleteInfoMutation, useAthleteProfile } from "../../../hooks/useAthleteProfile";
import { useAthleteProfileBuilderData } from "../../../hooks/useAthleteProfileBuilderData";

interface BuildAthleteInfoProps {
  onClose?: () => void;
  initialData?: AthleteInfo;
  customMutationFn?: (data: { info: AthleteInfo }) => void;
  title?: string;
  subheading?: string;
}

// Type for our form fields including the virtual confirmEmail field
type FormFields = AthleteInfo & {
  confirmEmail?: string;
};

export function BuildAthleteInfo({
  onClose,
  initialData,
  customMutationFn,
  title,
  subheading,
}: BuildAthleteInfoProps) {
  const [isLoading, setIsLoading] = useState(true);
  const [reviewText, setReviewText] = useState("");
  const [subheadingText, setSubheadingText] = useState(
    subheading || "Keep your information current and help us match you with the right opportunities."
  );
  const [linkedinImportRequested, setLinkedinImportRequested] = useState(false);
  const [headingText, setHeadingText] = useState(title || "Profile Details");
  const [isOnboarding] = useAppStore((state) => [state.isOnboarding]);
  const [athleteResumeData] = useAppStore((state) => [state.athleteResumeData]);
  const { data: athleteProfile, isSuccess: isSuccessAthleteProfile } = useAthleteProfile();
  const { mutate: updateAthleteInfo } = useAthleteInfoMutation(onClose);
  const toast = useToast();

  const formRef = useRef<HTMLFormElement>(null);
  const formMethods = useForm<FormFields>();

  // Create a separate state to track if emails match
  const [emailsMatch, setEmailsMatch] = useState(true);

  const [formConfigData, setFormConfigData] =
    useState<Record<keyof FormFields, FormFieldConfig<FormFields[keyof FormFields]>>>({
      ...athleteFormConfig,
      confirmEmail: {
        label: "Confirm Email",
        helperText: "Please confirm your email address",
        type: FormFieldType.TEXT,
        required: true,
      }
    });
  const formValues = formMethods.watch();
  const primaryEmail = formMethods.watch("primaryEmail");
  const confirmEmail = formMethods.watch("confirmEmail");

  // Function to handle LinkedIn import request
  const handleLinkedInImportRequest = () => {
    const newRequestState = !linkedinImportRequested;
    setLinkedinImportRequested(newRequestState);

    // Update the linkedInUrl field requirement based on the import request state
    setFormConfigData((prevConfig) => ({
      ...prevConfig,
      linkedInUrl: {
        ...prevConfig.linkedInUrl,
        required: newRequestState,
        helperText: newRequestState
          ? "Add your LinkedIn profile URL for the profile import"
          : "Add your LinkedIn profile URL",
      }
    }));

    if (newRequestState) {
      toast({
        title: "LinkedIn Import Requested",
        description: "Our team has been notified of your request.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } else {
      toast({
        title: "LinkedIn Import Cancelled",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Update email validation when either email field changes
  useEffect(() => {
    const emailsDoMatch = !confirmEmail ||
      (primaryEmail?.toLowerCase() === confirmEmail?.toLowerCase());
    setEmailsMatch(emailsDoMatch);

    if (confirmEmail && !emailsDoMatch) {
      formMethods.setError("confirmEmail", {
        type: "validate",
        message: "Email addresses do not match. Please check and try again.",
      });
    } else {
      formMethods.clearErrors("confirmEmail");
    }
  }, [primaryEmail, confirmEmail, formMethods]);

  // Update the confirmEmail field's error state in the form config
  useEffect(() => {
    setFormConfigData((prevConfig) => ({
      ...prevConfig,
      confirmEmail: {
        ...prevConfig.confirmEmail,
        helperText: !emailsMatch && confirmEmail
          ? "Email addresses must match"
          : "Please confirm your email address",
      }
    }));
  }, [emailsMatch, confirmEmail]);

  // Watch the discovery source to conditionally show the detail field
  const discoverySource = formMethods.watch("discoverySource");
  const needsDiscoveryDetail = discoverySource === "Other" || discoverySource === "HCA Ambassador";

  // Update the required status of discoveryDetail based on selection
  useEffect(() => {
    if (needsDiscoveryDetail) {
      setFormConfigData((prevConfig) => ({
        ...prevConfig,
        discoveryDetail: {
          ...prevConfig.discoveryDetail,
          required: true,
          helperText: discoverySource === "HCA Ambassador"
            ? "HCA Ambassador who referred you"
            : "Please specify how you found us",
        },
      }));
    } else {
      setFormConfigData((prevConfig) => ({
        ...prevConfig,
        discoveryDetail: {
          ...prevConfig.discoveryDetail,
          required: false,
        },
      }));
      // Clear the discovery detail if it's not needed
      if (formValues.discoveryDetail) {
        formMethods.setValue("discoveryDetail", "");
      }
    }
  }, [discoverySource, needsDiscoveryDetail]);

  useEffect(() => {
    if (!athleteResumeData) return;
    if (athleteProfile && athleteProfile.displayName && athleteResumeData) {
      setHeadingText("HCA is Making Moves");
      setSubheadingText(
        "Now using our new AI-driven resume based profile builder. We've combined the original profile data that you filled out along with your resume.",
      );
      setReviewText(
        "Before we show you your new profile, please review the fields below and make any necessary changes.",
      );
    } else {
      setHeadingText("HCA is Making Moves");
      setSubheadingText("Now using our new AI-driven resume based profile builder.");
      setReviewText(
        "Before we show you your new profile, please review the fields below and make any necessary changes.",
      );
    }
  }, [isSuccessAthleteProfile, athleteProfile, athleteResumeData]);

  const {
    universityList,
    sportList,
    handlePrimarySportChange,
    positions,
    isLoading: isLoadingData,
    isLoadingPositions,
  } = useAthleteProfileBuilderData();

  useEffect(() => {
    if (isLoadingData) return;

    if (universityList) {
      const universityOptions = universityList.map((university) => ({
        id: university.id.toString(),
        name: university.universityName ?? "",
      }));
      updateFormConfigOptions("primaryUniversityId", universityOptions);
    }

    if (sportList) {
      const sportOptions = sportList.map((sport) => ({
        id: sport.id.toString(),
        name: sport.sport,
      }));
      updateFormConfigOptions("primarySportId", sportOptions);
    }
  }, [isLoadingData, universityList, sportList]);

  useEffect(() => {
    if (isLoadingPositions) return;
    if (positions) {
      const positionOptions = positions.map((position) => ({
        id: position.id.toString(),
        name: position.position ?? "",
      }));
      updateFormConfigOptions("primaryPositionId", positionOptions);
    }
  }, [positions, isLoadingPositions]);

  useEffect(() => {
    handlePrimarySportChange(Number(formValues.primarySportId));
  }, [formValues.primarySportId]);

  useEffect(() => {
    if (formValues.primaryUniversityId) {
      const universityName =
        universityList?.find((u) => u.id === Number(formValues.primaryUniversityId))?.universityName ?? "";
      formMethods.setValue("primaryUniversityName", universityName);
    }
    if (formValues.primarySportId) {
      const sportName = sportList?.find((s) => s.id === Number(formValues.primarySportId))?.sport ?? "";
      formMethods.setValue("primarySport", sportName);
    }
    if (formValues.primaryPositionId) {
      const positionName = positions?.find((p) => p.id === Number(formValues.primaryPositionId))?.position ?? "";
      formMethods.setValue("primaryPosition", positionName);
    }
  }, [formValues.primaryUniversityId, formValues.primarySportId, formValues.primaryPositionId]);

  function updateFormConfigOptions(fieldName: keyof AthleteInfo, options: Array<{ id: string; name: string }>) {
    setFormConfigData((prevConfig) => ({
      ...prevConfig,
      [fieldName]: {
        ...prevConfig[fieldName],
        options,
      },
    }));
  }

  useEffect(() => {
    if (isLoadingData) return;

    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, [isLoadingData]);

  useEffect(() => {
    if (initialData) {
      // If initialData is provided, use it directly
      const formData = {
        ...initialData,
        primaryPhone: initialData.primaryPhone?.replace(/\D/g, "").replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3") ?? "",
        confirmEmail: initialData.primaryEmail,
      };
      formMethods.reset(formData);
    } else if (isSuccessAthleteProfile && athleteProfile) {
      // Otherwise fallback to existing behavior for the current user's profile
      // First set the athlete profile as the base
      const baseData = {
        ...athleteProfile,
        primaryPhone:
          athleteProfile.primaryPhone?.replace(/\D/g, "").replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3") ?? "",
        primaryUniversityId: Number(athleteProfile.primaryUniversityId),
        primarySportId: Number(athleteProfile.primarySportId),
        primaryPositionId: Number(athleteProfile.primaryPositionId) || null,
        educationLevel: athleteProfile.educationLevel ?? "",
        confirmEmail: athleteProfile.primaryEmail, // Initialize confirm email directly in the form data
      };

      // If we have resume data and are onboarding, fill in any blank fields
      if (athleteResumeData) {
        const cleanedResumeData = cleanResumeData(athleteResumeData);
        const mergedData = {
          ...baseData,
          ...cleanedResumeData,
          confirmEmail: baseData.primaryEmail,
        };

        // Only copy resume data for fields that are empty in the profile
        Object.keys(cleanedResumeData).forEach((key) => {
          const typedKey = key as keyof AthleteInfoEditableFields;
          if (!baseData[typedKey] && cleanedResumeData[typedKey]) {
            const typedValue = cleanedResumeData[typedKey] as unknown as (typeof baseData)[typeof typedKey];
            if (typedValue !== null) {
              // @ts-expect-error: Do I look like a magician?
              mergedData[typedKey] = typedValue;
            }
          }
        });

        formMethods.reset(mergedData);
      } else {
        formMethods.reset(baseData);
      }
    } else if (athleteResumeData) {
      // If no profile exists, use resume data
      const cleanedResumeData = cleanResumeData(athleteResumeData);
      const dataWithConfirmEmail = {
        ...cleanedResumeData,
        confirmEmail: cleanedResumeData.primaryEmail,
      };
      formMethods.reset(dataWithConfirmEmail);
    }
  }, [initialData, athleteResumeData, isOnboarding, isSuccessAthleteProfile, athleteProfile]);

  function cleanResumeData(resumeData: AthleteProfile) {
    const cleanedPhone = resumeData.primaryPhone?.replace(/\D/g, "").replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
    const cleanedAthleteInfo: AthleteInfoEditableFields = {
      firstName: resumeData.firstName,
      lastName: resumeData.lastName,
      displayName: resumeData.displayName,
      primaryEmail: resumeData.primaryEmail,
      linkedInUrl: resumeData.linkedInUrl,
      primaryPhone: cleanedPhone,
      primaryUniversityId: null,
      primaryUniversityName: "",
      primaryMajor: majorOptions.some((m) => m === resumeData.primaryMajor) ? resumeData.primaryMajor : "",
      primarySport: "",
      primarySportId: null,
      primaryPosition: "",
      primaryPositionId: null,
      educationLevel: educationLevelOptions.some((e) => e === resumeData.educationLevel)
        ? resumeData.educationLevel
        : "",
      graduationYear: resumeData.graduationYear,
      gpa: resumeData.gpa,
      currentLocation: stateOptions.some((s) => s === resumeData.currentLocation) ? resumeData.currentLocation : "",
      workPreference: preferredEmploymentOptions.some((e) => e === resumeData.workPreference)
        ? resumeData.workPreference
        : "",
      workType: preferredWorkEnvironmentOptions.some((e) => e === resumeData.workType) ? resumeData.workType : "",
      experienceYears: resumeData.experienceYears,
      // readyToWork: resumeData.readyToWork ?? true,
      willingToRelocate: resumeData.willingToRelocate ?? false,
      discoverySource: "",
      discoveryDetail: "",
      hasLinkedInImport: resumeData.hasLinkedInImport || false,
    };

    return cleanedAthleteInfo;
  }

  // Create field list with confirmEmail
  const fieldsToShow = [
    ...athleteBasicInfoFieldsToEdit.slice(0, athleteBasicInfoFieldsToEdit.indexOf("primaryEmail") + 1),
    "confirmEmail",
    ...athleteBasicInfoFieldsToEdit.slice(athleteBasicInfoFieldsToEdit.indexOf("primaryEmail") + 1)
  ].filter(field =>
    (field !== "discoveryDetail" || needsDiscoveryDetail) &&
    field !== "readyToWork"
  ) as Array<keyof FormFields>;

  const handleSubmit: SubmitHandler<FormFields> = async (data) => {
    let hasErrors = false;
    const errorMessages: string[] = [];

    // Validate that emails match (case-insensitive)
    if (data.primaryEmail?.toLowerCase() !== data.confirmEmail?.toLowerCase()) {
      formMethods.setError("confirmEmail", {
        type: "validate",
        message: "Email addresses do not match. Please check and try again.",
      });
      hasErrors = true;
      errorMessages.push("Email addresses do not match");
    }

    // Validate that if LinkedInUrl is required, it has a value
    if (linkedinImportRequested && !data.linkedInUrl) {
      formMethods.setError("linkedInUrl", {
        type: "required",
        message: "LinkedIn URL is required for import",
      });
      hasErrors = true;
      errorMessages.push("LinkedIn URL is required for import");
    }

    // Validate that if discoveryDetail is required, it has a value
    if (needsDiscoveryDetail && !data.discoveryDetail) {
      formMethods.setError("discoveryDetail", {
        type: "required",
        message: "Please provide additional details about how you found us",
      });
      hasErrors = true;
      errorMessages.push("Additional details are required");
    }

    // Check for any other form validation errors
    const formErrors = formMethods.formState.errors;
    if (Object.keys(formErrors).length > 0) {
      hasErrors = true;
      // Add any other error messages that we haven't already captured
      Object.entries(formErrors).forEach(([field, error]) => {
        if (field !== 'confirmEmail' && field !== 'discoveryDetail') {
          errorMessages.push(`${field}: ${error.message}`);
        }
      });
    }

    // Show toast if there are errors
    if (hasErrors) {
      toast({
        title: "Please fix the following errors:",
        description: errorMessages.length > 0
          ? errorMessages.join("\n")
          : "Please fix the highlighted fields before submitting.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      return;
    }

    // Remove the confirmEmail field before sending to API
    // Using object destructuring to remove the field
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { confirmEmail: _, ...athleteData } = data;

    // Ensure email is stored in lowercase in the database
    if (athleteData.primaryEmail) {
      athleteData.primaryEmail = athleteData.primaryEmail.toLowerCase();
    }

    // Convert empty GPA string to null
    if (!athleteData.gpa && athleteData.gpa !== 0) {
      athleteData.gpa = null;
    }

    // Ensure boolean fields are properly set
    athleteData.willingToRelocate = athleteData.willingToRelocate === true;

    // If readyToWork is undefined, set it to false instead of undefined
    if (athleteData.readyToWork === undefined) {
      athleteData.readyToWork = false;
    }

    // Prepare the data to submit
    const submitData = {
      info: athleteData as AthleteInfo,
      linkedinImportRequested: linkedinImportRequested
    };

    // Use customMutationFn if provided, otherwise use the default updateAthleteInfo
    if (customMutationFn) {
      customMutationFn(submitData);
    } else {
      updateAthleteInfo(submitData);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLFormElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  if (isLoading) {
    return (
      <Center h="90vh" flexDirection="column" gap={4}>
        <Heading>Loading profile builder...</Heading>
        <Spinner color="brand.500" size="xl" speed="1.0s" thickness="4px" />
      </Center>
    );
  }

  // Show LinkedIn import only if onboarding and no resume data
  const showLinkedInImport = !athleteProfile?.firstName && !athleteResumeData;

  return (
    <Page>
      <VStack align="stretch" spacing={[3, 6]} bg="white" p={[6, 12]} borderRadius="lg" shadow="md" maxW="600px">
        <Heading textAlign="center">{headingText}</Heading>
        <Text textAlign={["center", "left"]}>{subheadingText}</Text>
        {athleteResumeData && <Text fontWeight="bold">{reviewText}</Text>}

        {/* Add LinkedIn import button at the top of the form */}
        {showLinkedInImport && (
          <LinkedInImportButton
            isClicked={linkedinImportRequested}
            onClick={handleLinkedInImportRequest}
          />
        )}

        <form ref={formRef} onSubmit={formMethods.handleSubmit(handleSubmit)} onKeyDown={handleKeyDown}>
          <DynamicForm<FormFields>
            fieldsToEdit={fieldsToShow}
            formData={formMethods.getValues()}
            formMethods={formMethods}
            formConfig={formConfigData}
          />
          {!emailsMatch && confirmEmail && (
            <FormErrorMessage mt={2} mb={4}>
              Email addresses do not match. Please check and try again.
            </FormErrorMessage>
          )}
          <Box display="flex" flexDirection="column" gap={4} mt={4}>
            {/* Add LinkedIn import button at the bottom of the form */}
            {showLinkedInImport && (
              <LinkedInImportButton
                isClicked={linkedinImportRequested}
                onClick={handleLinkedInImportRequest}
              />
            )}

            <Box display="flex" justifyContent="flex-end" gap={2}>
              <Button
                type="submit"
                colorScheme="brand"
                alignSelf="flex-end"
              >
                Save
              </Button>
            </Box>
          </Box>
        </form>
      </VStack>
    </Page>
  );
}
