import { forwardRef, useImperativeHandle, useRef } from "react";

import { ColDef, ICellRendererParams } from "@ag-grid-community/core";
import { AgGridReact } from "@ag-grid-community/react";
import { Box, Center, Heading, Spinner, VStack, Link, Grid, GridItem } from "@chakra-ui/react";
import { AthleteSignUpInfo } from "@shared/athleteModels";
import { useQuery } from "@tanstack/react-query";

import { UniversityAthleteCount } from "../../../../../shared/models";
import { getHCAInsights } from "../../services/admin-api";

export interface HCAInsights {
  universityAthleteCounts: UniversityAthleteCount[];
  allAthleteSignups: AthleteSignUpInfo[];
}

type CellRendererParams = ICellRendererParams & { data: UniversityAthleteCount };

export const InsightsTab = forwardRef((_, ref) => {
  const { data: insights, isLoading } = useQuery<HCAInsights>({
    queryKey: ["insights"],
    queryFn: () => getHCAInsights(),
  });

  const universityGridRef = useRef<AgGridReact>(null);
  const athleteSignupsGridRef = useRef<AgGridReact>(null);

  // Expose methods to parent component
  useImperativeHandle(ref, () => ({
    exportToCSV: () => {
      // By default, export the larger dataset (athlete signups)
      if (athleteSignupsGridRef.current && athleteSignupsGridRef.current.api) {
        const allColumns = athleteSignupsGridRef.current.api.getAllGridColumns();

        athleteSignupsGridRef.current.api.exportDataAsCsv({
          fileName: `athlete-signups-export-${new Date().toISOString().split('T')[0]}.csv`,
          columnKeys: allColumns.map(col => col.getColId()),
          skipColumnHeaders: false,
          skipColumnGroupHeaders: true,
          suppressQuotes: false,
          processCellCallback: (params) => {
            // Check if this is a date column based on field name
            const isDateField = params.column.getColId().includes('createdAt') || 
                               params.column.getColId().includes('updatedAt') ||
                               params.column.getColId().includes('SignInAt');
            
            // Format date values as MM/DD/YYYY
            if (params.value instanceof Date) {
              const month = (params.value.getMonth() + 1).toString().padStart(2, '0');
              const day = params.value.getDate().toString().padStart(2, '0');
              const year = params.value.getFullYear();
              return `${month}/${day}/${year}`;
            } else if (typeof params.value === 'string' && /^\d{4}-\d{2}-\d{2}T/.test(params.value)) {
              // ISO date string format (e.g. "2023-01-15T00:00:00.000Z")
              const date = new Date(params.value);
              if (!isNaN(date.getTime())) {
                const month = (date.getMonth() + 1).toString().padStart(2, '0');
                const day = date.getDate().toString().padStart(2, '0');
                const year = date.getFullYear();
                return `${month}/${day}/${year}`;
              }
            } else if (isDateField || (typeof params.value === 'number' && params.value > 1000000000000)) {
              // Handle Unix timestamp in milliseconds (large numbers)
              // Typical Unix timestamps in milliseconds are 13 digits (> 1 trillion)
              const date = new Date(params.value);
              if (!isNaN(date.getTime())) {
                const month = (date.getMonth() + 1).toString().padStart(2, '0');
                const day = date.getDate().toString().padStart(2, '0');
                const year = date.getFullYear();
                return `${month}/${day}/${year}`;
              }
            }
            
            return params.value !== undefined ? params.value : '';
          }
        });
      }

      // Also export the university counts data
      if (universityGridRef.current && universityGridRef.current.api) {
        const allColumns = universityGridRef.current.api.getAllGridColumns();

        universityGridRef.current.api.exportDataAsCsv({
          fileName: `university-counts-export-${new Date().toISOString().split('T')[0]}.csv`,
          columnKeys: allColumns.map(col => col.getColId()),
          skipColumnHeaders: false,
          skipColumnGroupHeaders: true,
          suppressQuotes: false,
          processCellCallback: (params) => {
            // Check if this is a date column based on field name
            const isDateField = params.column.getColId().includes('createdAt') || 
                               params.column.getColId().includes('updatedAt') ||
                               params.column.getColId().includes('SignInAt');
            
            // Format date values as MM/DD/YYYY
            if (params.value instanceof Date) {
              const month = (params.value.getMonth() + 1).toString().padStart(2, '0');
              const day = params.value.getDate().toString().padStart(2, '0');
              const year = params.value.getFullYear();
              return `${month}/${day}/${year}`;
            } else if (typeof params.value === 'string' && /^\d{4}-\d{2}-\d{2}T/.test(params.value)) {
              // ISO date string format (e.g. "2023-01-15T00:00:00.000Z")
              const date = new Date(params.value);
              if (!isNaN(date.getTime())) {
                const month = (date.getMonth() + 1).toString().padStart(2, '0');
                const day = date.getDate().toString().padStart(2, '0');
                const year = date.getFullYear();
                return `${month}/${day}/${year}`;
              }
            } else if (isDateField || (typeof params.value === 'number' && params.value > 1000000000000)) {
              // Handle Unix timestamp in milliseconds (large numbers)
              // Typical Unix timestamps in milliseconds are 13 digits (> 1 trillion)
              const date = new Date(params.value);
              if (!isNaN(date.getTime())) {
                const month = (date.getMonth() + 1).toString().padStart(2, '0');
                const day = date.getDate().toString().padStart(2, '0');
                const year = date.getFullYear();
                return `${month}/${day}/${year}`;
              }
            }
            
            return params.value !== undefined ? params.value : '';
          }
        });
      }
    }
  }));

  if (isLoading) {
    return (
      <Center w={"full"} h={"full"}>
        <Spinner />
      </Center>
    );
  }

  type GridData = UniversityAthleteCount[] | AthleteSignUpInfo[] | undefined;

  const renderGrid = (title: string, data: GridData, columns: ColDef[], gridRef: React.RefObject<AgGridReact>) => {
    if (!data) {
      return null;
    }
    return (
      <Box w="full" mb={6}>
        <Heading size={["md"]} mb={2}>
          {title}
        </Heading>
        <Box 
          className="ag-theme-quartz" 
          sx={{ 
            width: ["220%", "100%"], 
            height: ["400px", "500px", "700px"]
          }}
        >
          <AgGridReact
            ref={gridRef}
            rowData={data}
            columnDefs={columns}
            defaultColDef={{ sortable: true, filter: true, resizable: true }}
            pagination={true}
            paginationAutoPageSize={true}
          />
        </Box>
      </Box>
    );
  };

  return (
    <VStack 
      w={["100%"]} 
      overflowY={"auto"} 
      h={["auto", "auto", "80vh"]} 
      spacing={4} 
      pb={8}
    >
      <Grid
        templateColumns={{ base: "repeat(1, 1fr)", xl: "repeat(6, 1fr)", "2xl": "repeat(12, 1fr)" }}
        gap={4}
        w="full"
      >
        <GridItem colSpan={{ base: 1, xl: 6, "2xl": 3 }}>
          {renderGrid("University Athlete Counts", insights?.universityAthleteCounts, [
            {
              headerName: "University",
              field: "universityName",
              flex: 2,
              cellRenderer: (params: CellRendererParams) => {
                // build a link to the tab=athletes&university=universityName
                const url = new URL(`/admin`, window.location.origin);
                url.searchParams.set("tab", "athletes");
                url.searchParams.set("university", params.data.universityName);
                return <Link href={url.toString()}>{params.data.universityName}</Link>;
              },
            },
            { headerName: "Active Athletes", field: "activeAthleteCount", flex: 1 },
          ], universityGridRef)}
        </GridItem>
        <GridItem colSpan={{ base: 1, xl: 6, "2xl": 9 }}>
          {renderGrid("All Athlete Signups", insights?.allAthleteSignups, [
            { headerName: "Display Name", field: "athleteInfo.displayName", flex: 2 },
            { headerName: "Sign Up Email", field: "emailAddress", flex: 2 },
            { headerName: "Primary Email", field: "athleteInfo.primaryEmail", flex: 2 },
            { headerName: "University", field: "athleteInfo.primaryUniversityName", flex: 2 },
            {
              headerName: "Signup Date",
              field: "clerkInfo.createdAt",
              flex: 1,
              valueFormatter: (params) => {
                if (!params.value) return "No Clerk Record";
                const date = new Date(params.value);
                const month = (date.getMonth() + 1).toString().padStart(2, '0');
                const day = date.getDate().toString().padStart(2, '0');
                const year = date.getFullYear();
                return `${month}/${day}/${year}`;
              }
            },
            {
              headerName: "Last Sign In",
              field: "clerkInfo.lastSignInAt",
              flex: 1,
              valueFormatter: (params) => {
                if (!params.value) return "No Clerk Record";
                const date = new Date(params.value);
                const month = (date.getMonth() + 1).toString().padStart(2, '0');
                const day = date.getDate().toString().padStart(2, '0');
                const year = date.getFullYear();
                return `${month}/${day}/${year}`;
              }
            },
            {
              headerName: "Athlete Profile Created",
              field: "athleteInfo.createdAt",
              flex: 1,
              valueFormatter: (params) => {
                if (!params.value) return "No Athlete Profile";
                const date = new Date(params.value);
                const month = (date.getMonth() + 1).toString().padStart(2, '0');
                const day = date.getDate().toString().padStart(2, '0');
                const year = date.getFullYear();
                return `${month}/${day}/${year}`;
              }
            },
            {
              headerName: "Last Profile Update",
              field: "athleteInfo.updatedAt",
              flex: 1,
              valueFormatter: (params) => {
                if (!params.value) return "No Athlete Profile";
                const date = new Date(params.value);
                const month = (date.getMonth() + 1).toString().padStart(2, '0');
                const day = date.getDate().toString().padStart(2, '0');
                const year = date.getFullYear();
                return `${month}/${day}/${year}`;
              }
            },
          ], athleteSignupsGridRef)}
        </GridItem>
      </Grid>
    </VStack>
  );
});
