import { Grid, GridItem } from "@chakra-ui/react";

import AthleteProfileCard from "./AthleteProfileCard";
import { DashboardProfileSection } from "./dashboard/AthleteDashboardPage";
import { AthleteProfile } from "../../../../../shared/athleteModels";
import { emptySectionStarter } from "../../../../../shared/resumeContentConfig";
import { AthleteProfileContentCard } from "../../components/cards/AthleteProfileContentCard";
import { Page } from "../../components/Page";
import { useProfileSections } from "../../hooks/useProfileSections";

interface AthleteProfileProps {
  athleteProfile: AthleteProfile;
  profileImage: { url: string } | null;
  hideEmptySections?: boolean;
  onEdit?: (updatedProfile: AthleteProfile) => void;
  showEdit?: boolean;
}

export function AthletePage({ athleteProfile, profileImage, hideEmptySections = false, onEdit, showEdit = false }: AthleteProfileProps) {
  const { groupFlexSectionsIntoRows, determineColSpan, mergeSections } = useProfileSections();

  const isEditMode = !!onEdit;

  // If hideEmptySections is true, filter out empty sections
  // If hideEmptySections is false, show all sections including empty template sections
  const filteredSections = hideEmptySections
    ? athleteProfile.sections.filter(section => section.content !== "")
    : mergeSections(
      emptySectionStarter,
      athleteProfile.sections,
      []
    );

  const rowGroupedSections = groupFlexSectionsIntoRows(filteredSections);

  const summarySection = filteredSections.find((section) => section.type === "summary");
  const workExperienceSection = filteredSections.find((section) => section.type === "work_experience");

  const topRowFlexSections = rowGroupedSections?.[0] ?? [];
  const theRestFlexSections = rowGroupedSections?.slice(1) ?? [];

  // Handler for editing sections
  const handleSectionEdit = (updatedSection: DashboardProfileSection) => {
    if (!onEdit) return;

    // Check if this section already exists in the profile
    const sectionExists = athleteProfile.sections.some(section => section.id === updatedSection.id);

    let updatedSections;
    if (sectionExists) {
      // Update existing section
      updatedSections = athleteProfile.sections.map(section =>
        section.id === updatedSection.id ? updatedSection : section
      );
    } else {
      // Add new section
      updatedSections = [...athleteProfile.sections, updatedSection];
    }

    // Create a new profile with the updated sections
    const updatedProfile = {
      ...athleteProfile,
      sections: updatedSections
    };

    // Call the parent's onEdit function with the updated profile
    onEdit(updatedProfile);
  };

  return (
    <Page>
      <Grid templateColumns={{ base: "1fr", md: "repeat(3, 1fr)" }} gap={4} w="100%">
        <GridItem colSpan={{ base: 1, md: 3 }}>
          <AthleteProfileCard
            athleteProfile={athleteProfile}
            profileImage={profileImage}
            isAdmin={showEdit}
            editMode={isEditMode}
          />
        </GridItem>

        {summarySection && (
          <GridItem colSpan={{ base: 1, md: 3 }}>
            <AthleteProfileContentCard
              section={summarySection}
              onEdit={isEditMode ? handleSectionEdit : undefined}
            />
          </GridItem>
        )}

        {topRowFlexSections.length > 0 && (
          <>
            {/* Special case: If there are exactly 2 sections and both would have width 2, make them full width */}
            {topRowFlexSections.length === 2 &&
              determineColSpan(topRowFlexSections[0]) === 2 &&
              determineColSpan(topRowFlexSections[1]) === 2 ? (
              // Two sections with width 2 - make both full width
              topRowFlexSections.map((section) => (
                <GridItem key={`full-width-section-${section.id || section.type}`} colSpan={{ base: 1, md: 3 }}>
                  <AthleteProfileContentCard
                    section={section}
                    onEdit={isEditMode ? handleSectionEdit : undefined}
                  />
                </GridItem>
              ))
            ) : (
              // Normal rendering for all other cases
              topRowFlexSections.map((section) => (
                <GridItem
                  key={`top-row-section-${section.id || section.type}`}
                  colSpan={{
                    base: 1,
                    md: topRowFlexSections.length === 1 ? 3 : determineColSpan(section),
                  }}
                >
                  <AthleteProfileContentCard
                    section={section}
                    onEdit={isEditMode ? handleSectionEdit : undefined}
                  />
                </GridItem>
              ))
            )}
          </>
        )}

        {workExperienceSection && (
          <GridItem colSpan={{ base: 1, md: 3 }}>
            <AthleteProfileContentCard
              section={workExperienceSection}
              onEdit={isEditMode ? handleSectionEdit : undefined}
            />
          </GridItem>
        )}

        {theRestFlexSections.map((row, rowIndex) => {
          // Special case: If there are exactly 2 sections in this row and both would have width 2, 
          // make them full width
          const hasTwoWidth2Sections = row.length === 2 &&
            determineColSpan(row[0]) === 2 &&
            determineColSpan(row[1]) === 2;

          return row.map((section) => (
            <GridItem
              key={`row-${rowIndex}-section-${section.id || section.type}`}
              colSpan={{
                base: 1,
                md: (hasTwoWidth2Sections || row.length === 1) ? 3 : determineColSpan(section),
              }}
            >
              <AthleteProfileContentCard section={section} onEdit={isEditMode ? handleSectionEdit : undefined} />
            </GridItem>
          ));
        })}
      </Grid>
    </Page>
  );
}
