import type { PathRouteProps } from "react-router-dom";

import UserRoleBasedRedirect from "./UserRoleBasedRedirect";
import { RoutePath } from "../../../../shared/models";
import AccountPage from "../pages/AccountPage";
import { AdminPage } from "../pages/admin";
import MagicPage from "../pages/ai-coach/AICoachMagicPage";
import { AdminEditAthletePage } from "../pages/athlete/AdminEditAthletePage";
import { AthleteProfilePage } from "../pages/athlete/AthleteProfilePage";
import { AthleteDashboardPage } from "../pages/athlete/dashboard/AthleteDashboardPage";
import InternPage from "../pages/athlete/InternPage";
import { SpecificAthleteProfilePage } from "../pages/athlete/SpecificAthleteProfilePage";
import FeedbackPage from "../pages/feedback/FeedbackPage";
import { FindAthletesPage } from "../pages/find-athletes";
import { AthleteOnboardingPage } from "../pages/onboarding/athlete-onboarding";
import UniversityPlaceholderPage from "../pages/UniversityPlaceholderPage";

interface HCAPathRouteProps extends PathRouteProps {
  bodyPadding?: number[];
  bgColor?: string;
}

export const routes: Array<HCAPathRouteProps> = [];

export const privateRoutes: Array<HCAPathRouteProps> = [
  {
    path: RoutePath.ROOT,
    element: <UserRoleBasedRedirect />,
  },
  {
    path: RoutePath.ATHLETE_ONBOARDING,
    element: <AthleteOnboardingPage />,
  },
  {
    path: RoutePath.ATHLETE_DASHBOARD,
    element: <AthleteDashboardPage />,
    // element: <AthleteDashboardPage />,
  },
  {
    path: RoutePath.ATHLETE_PROFILE,
    element: <AthleteProfilePage />,
  },
  {
    path: RoutePath.INTERN,
    element: <InternPage />,
  },
  {
    path: RoutePath.UNIVERSITY_DASHBOARD,
    element: <UniversityPlaceholderPage />,
  },
  // {
  //   path: RoutePath.FIND_COMPANIES,
  //   element: <FindCompaniesPage />
  // },
  // {
  //   path: RoutePath.COMPANY_DASHBOARD,
  //   element: <CompanyDashboardPage />
  // },
  {
    path: RoutePath.FIND_ATHLETES,
    element: <FindAthletesPage />,
  },
  // {
  //   path: RoutePath.COMPANY_PROFILE,
  //   element: <CompanyProfilePage />
  // },
  {
    path: RoutePath.ADMIN,
    element: <AdminPage />,
  },
  {
    path: RoutePath.COACH,
    element: <MagicPage />,
  },
  {
    path: RoutePath.ACCOUNT,
    element: <AccountPage />,
    bodyPadding: [0],
    bgColor: "white",
  },
  {
    path: RoutePath.SPECIFIC_ATHLETE_PROFILE,
    element: <SpecificAthleteProfilePage />,
  },
  {
    path: RoutePath.ADMIN_EDIT_ATHLETE_PROFILE,
    element: <AdminEditAthletePage />,
  },
  // {
  //   path: RoutePath.SPECIFIC_COMPANY_PROFILE,
  //   element: <SpecificCompanyProfilePage />
  // },
  {
    path: RoutePath.FEEDBACK,
    element: <FeedbackPage />,
  },
];
